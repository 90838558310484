import { MbscModule } from '@mobiscroll/angular';
import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, NgIf, NgTemplateOutlet } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DndDirective } from './directives/drag-and-drop.directive';
import { DebounceClickDirective } from './directives/debounce/debounce-click.directive';
import { DebounceKeyupDirective } from './directives/debounce/debounce-keyup.directive';
import { DebounceChangeDirective } from './directives/debounce/debounce-change.directive';
import { PreventMultiClickEventsDirective } from './directives/prevent-multi-click-events.directive';
import { ElStoredFileSrcDirective } from './directives/stored-file.directive';

import { ModalComponent } from './components/modal/modal.component';
import { ButtonComponent } from './components/button/button.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ContactComponent } from './components/modal/contact/contact.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { CalendarDropdownComponent } from './components/calendar-dropdown/calendar-dropdown.component';
import { DeclineAssignmentComponent } from './components/modal/decline-assignment/decline-assignment.component';
import { NoUpdatesComponent } from './components/no-updates/no-updates.component';
import { AcceptAssignmentComponent } from './components/modal/accept-assignment/accept-assignment.component';
import { IndianaComponent } from './components/modal/accept-assignment/contracts/_indiana_ihsaa';
import { ColoradoComponent } from './components/modal/accept-assignment/contracts/_colorado_chsaa';
import { FloridaComponent } from './components/modal/accept-assignment/contracts/_florida_fhsaa';
import { IllinoisComponent } from './components/modal/accept-assignment/contracts/_illinois_ihsa';
import { KentuckyComponent } from './components/modal/accept-assignment/contracts/_kentucky_khsaa';
import { MichiganComponent } from './components/modal/accept-assignment/contracts/_michigan_mhsaa';
import { MissouriComponent } from './components/modal/accept-assignment/contracts/_missouri_mshsaa';
import { NoAssociationComponent } from './components/modal/accept-assignment/contracts/_noassociation';
import { OhioComponent } from './components/modal/accept-assignment/contracts/_ohio_ohsaa';
import { ErrorComponent } from './components/modal/error/error.component';
import { SuccessComponent } from './components/modal/success/success.component';
import { ShareComponent } from './components/modal/share/share-modal.component';
import { ContactPartnerComponent } from './components/modal/contact-partner/contact-partner.component';
import { EventDetailsComponent } from './components/modal/event-details/event-details.component';
import { BarcodeScanningModalComponent } from './components/barcode/barcode-scanning-modal.component';
import { CheckoutComponent } from './components/modal/checkout/checkout-modal.component';
import { ForgotPasswordComponent } from './components/modal/login/forgot-password.component';
import { ForgotUsernameComponent } from './components/modal/login/forgot-username.component';
import { MfaLoginComponent } from './components/modal/login/mfa-login.component';
import { CheckoutCashComponent } from './components/modal/checkout/cash/cash-modal.component';
import { KioskCheckoutComponent } from './components/modal/checkout/kiosk-card/kiosk-card-modal.component';
import { KioskPastOrderComponent } from './components/modal/past-order/past-order-modal.component';
import { AcceptTermsComponent } from './components/modal/pay/accept-terms.component';
import { AcceptPrivacyPolicyComponent } from './components/modal/pay/accept-privacy-policy.component';
import { RegisterAccountComponent } from './components/modal/pay/register-account.component';
import { Accept1099TermsComponent } from './components/modal/pay/1099-accept-terms.component';
import { SearchPaymentsComponent } from './components/modal/pay/search-payments.component';
import { VerifyPasswordTaxComponent } from './components/modal/pay/verify-password-tax.component';
import { TaxProfileComponent } from './components/modal/pay/tax-profile.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { UpdateInformationComponent } from './components/modal/pay/update-information.component';
import { AddBankAccountComponent } from './components/modal/pay/add-bank-account.component';
import { VerifyMicroDepositsComponent } from './components/modal/pay/verify-micro-deposits.component';
import { ManageCalendarsComponent } from './components/modal/calendar/manage-calendars.component';
import { OfficialInformationComponent } from './components/modal/official-information/official-information.component';
import { LinkedEventsComponent } from './components/modal/linked-events/linked-events.component';
import { ChangePasswordComponent } from './components/modal/change-password/change-password.component';
import { ManageSchoolsComponent } from './components/modal/manage-schools/manage-schools.component';
import { AcceptDwollaTermsComponent } from './components/modal/pay/accept-dwolla-terms.component';
import { CreateArticleComponent } from './components/modal/article/create-article-modal/create-article-modal.component';

@NgModule({
  declarations: [
    ModalComponent,
    ButtonComponent,
    DndDirective,
    DebounceClickDirective,
    DebounceKeyupDirective,
    DebounceChangeDirective,
    PreventMultiClickEventsDirective,
    ElStoredFileSrcDirective,
    ProgressBarComponent,
    ContactComponent,
    FooterComponent,
    HeaderComponent,
    BarcodeScanningModalComponent,
    CalendarDropdownComponent,
    NoUpdatesComponent,
    DeclineAssignmentComponent,
    AcceptAssignmentComponent,
    ColoradoComponent,
    FloridaComponent,
    IllinoisComponent,
    IndianaComponent,
    KentuckyComponent,
    MichiganComponent,
    MissouriComponent,
    NoAssociationComponent,
    OhioComponent,
    ShareComponent,
    ErrorComponent,
    ContactPartnerComponent,
    EventDetailsComponent,
    SuccessComponent,
    CheckoutComponent,
    ForgotPasswordComponent,
    ForgotUsernameComponent,
    CheckoutCashComponent,
    KioskCheckoutComponent,
    KioskPastOrderComponent,
    MfaLoginComponent,
    AcceptTermsComponent,
    AcceptDwollaTermsComponent,
    AcceptPrivacyPolicyComponent,
    RegisterAccountComponent,
    Accept1099TermsComponent,
    SearchPaymentsComponent,
    VerifyPasswordTaxComponent,
    TaxProfileComponent,
    LoadingIndicatorComponent,
    UpdateInformationComponent,
    AddBankAccountComponent,
    VerifyMicroDepositsComponent,
    ManageCalendarsComponent,
    OfficialInformationComponent,
    LinkedEventsComponent,
    ChangePasswordComponent,
    ManageSchoolsComponent,
    CreateArticleComponent
  ],
  imports: [
    MbscModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    FontAwesomeModule,
    AsyncPipe,
    NgIf,
    NgTemplateOutlet,
  ],
  exports: [
    ModalComponent,
    ButtonComponent,
    DndDirective,
    DebounceClickDirective,
    DebounceKeyupDirective,
    DebounceChangeDirective,
    PreventMultiClickEventsDirective,
    ElStoredFileSrcDirective,
    ProgressBarComponent,
    ContactComponent,
    FooterComponent,
    HeaderComponent,
    BarcodeScanningModalComponent,
    CalendarDropdownComponent,
    NoUpdatesComponent,
    ErrorComponent,
    ShareComponent,
    ContactPartnerComponent,
    EventDetailsComponent,
    SuccessComponent,
    CheckoutComponent,
    CheckoutCashComponent,
    KioskCheckoutComponent,
    KioskPastOrderComponent,
    MfaLoginComponent,
    AcceptTermsComponent,
    AcceptDwollaTermsComponent,
    AcceptPrivacyPolicyComponent,
    RegisterAccountComponent,
    Accept1099TermsComponent,
    SearchPaymentsComponent,
    VerifyPasswordTaxComponent,
    TaxProfileComponent,
    LoadingIndicatorComponent,
    UpdateInformationComponent,
    AddBankAccountComponent,
    VerifyMicroDepositsComponent,
    ManageCalendarsComponent,
    OfficialInformationComponent,
    LinkedEventsComponent,
    ChangePasswordComponent,
    ManageSchoolsComponent,
    CreateArticleComponent
  ],
})
export class SharedModule {}
