<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Accept Dwolla TOS</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="contract-container" class="ion-padding-horizontal">
    <h2>Dwolla Privacy Policy & Terms and Conditions Of Service</h2>
    <p>This website, eventlink.com (the "Service"), is a service provided by SDI Innovations, Inc. (&ldquo;SDI&rdquo;) d/b/a EventLink.</p>
    <p>By clicking &ldquo;I Agree below&rdquo; you are also agreeing that you have reviewed and agree to be bound by the <a href="https://www.dwolla.com/legal/tos" target="_blank">Dwolla Terms of Service</a> and <a href="https://www.dwolla.com/legal/privacy" target="_blank">Dwolla Privacy Policy.</a></p>
 
    <p>If you do not agree to any term or condition of service herein, then do not use the Service.</p>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="acceptTerms()"
          expand="block">
          <ion-text> I Accept </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
