<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Accept TOS</ion-title>
  </ion-toolbar>
</ion-header>


<ion-content>
  <div  class="ion-padding-horizontal">
    <p class="no-top-margin"><b>Last Updated: November 16, 2022</b></p>
    <h2>1099 Electronic Delivery Consent</h2>
    <p>By using Eventlink’s services you agree to and understand the following:</p>
    <ul>
      <li>You are opting in to receiving an electronic copy of your 1099-K form.</li>
      <li>This consent to receive an electronic 1099 will remain in place so long as you do not opt out.</li>
      <li>Any electronic 1099s made available to you will remain available indefinitely until you request that your Eventlink account be closed and/or deleted.</li>
      <li>You can update your tax information in your Eventlink account.</li>
      <li>You can withdraw your consent and elect to receive a paper copy of the 1099 by emailing Eventlink customer support with your name and address, after verification of your identity.</li>
    </ul>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="acceptTerms()"
          expand="block">
          <ion-text> I Agree </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
