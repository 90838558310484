<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title> Verify Micro Deposits</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-padding-top">
    <span class="input-row">
      <ion-item lines="none">
        <ion-text>
          Please enter the two deposit amounts found in your bank account that are less than or equal to $0.10.
        </ion-text>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-text>
          After 3 incorrect attempts, this account will be removed and the process will need to be started again from the beginning.
          <b>Do not attempt to guess the deposit amounts.</b> Please contact Eventlink Customer Success &#64; (866) 330-7710 if you have any questions before proceeding.
        </ion-text>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-select label-placement="stacked"  [(ngModel)]="amount1">
          <div slot="label">Amount 1 <ion-text color="danger">*</ion-text></div>
          <ion-select-option *ngFor="let amount of amounts" [value]="amount.ID">{{
            amount.Title
          }}</ion-select-option>
        </ion-select>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-select label-placement="stacked" [(ngModel)]="amount2">
          <div slot="label">Amount 2 <ion-text color="danger">*</ion-text></div>
          <ion-select-option *ngFor="let amount of amounts" [value]="amount.ID">{{
            amount.Title
          }}</ion-select-option>
        </ion-select>
      </ion-item>
    </span>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="submit()"
          expand="block"
          [disabled]="amount2===0 || amount1===0">
          <ion-text> Verify </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
