
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Verify Password</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="formGroup" class="ion-padding-horizontal">
    <span class="input-row">
      <ion-item lines="none">
        <ion-text>
          Confirm your Eventlink password to securely access your sensitive tax information.
        </ion-text>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          type="password"
          formControlName="password">
          <div slot="label">Password <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>

  </form>

  <div class="content-sticky-footer bottom-bkp-05">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="sendPassword()"
          expand="block"
          [disabled]="!formGroup.valid">
          <ion-text> Confirm </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
