import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorService } from '../../../../core/services/error/error.service';
import { PaymentLegacyApiService } from 'src/app/core/services/api/v1/PaymentLegacyApi.service';
import { UserLegacyApiService } from 'src/app/core/services/api/v1/UserLegacyApi.service';
import { dtoUserLongForm } from 'src/app/core/services/api/models/legacy/dtoUserLongForm';
import { DwollaCustomApiService } from 'src/app/core/services/api/dwolla/dwolla.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.scss'],
})
export class AddBankAccountComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  formGroup: FormGroup;
  @Input() name: string;
  @Input() type: string;
  @Input() routingNumber: string;
  @Input() accountNumber: string;
  user: dtoUserLongForm = {
    ID: undefined,
    Username: undefined,
    FirstName: undefined,
    LastName: undefined,
    Address1: undefined,
    Address2: undefined,
    County: undefined,
    City: undefined,
    State: undefined,
    Zip: undefined,
    TimeZone: undefined,
    Latitude: undefined,
    Longitude: undefined,
    LastSignedIn: undefined,
    DefaultApplication: undefined,
    ContactPoints: undefined,
  };
  customer: any;
  token: string = '';

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly errorService: ErrorService,
    private readonly paymentService: PaymentLegacyApiService,
    private readonly fb: FormBuilder,
    private readonly userService: UserLegacyApiService,
    private readonly dwollaCustomService: DwollaCustomApiService
  ) {
    this.name = '';
    this.type = '';
    this.routingNumber = '';
    this.accountNumber = '';

    this.formGroup = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(49)]],
      type: ['', [Validators.required]],
      routingNumber: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      accountNumber: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
    const userResponse = await this.userService.find(this.context.UserID!);
    if (userResponse.isAnyError) {
      return;
    }
    this.user = userResponse.data!;
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async saveAccount() {
    let customerUrl = environment.dwolla.url + '/customers/' + this.customer.ID;
    let dwollaRequest = {
      action: 'customer.fundingsources.create',
      links: {
        customer: {
          href: customerUrl,
        },
      },
      _links: {
        customer: {
          href: customerUrl,
        },
      },
    };
    const tokenResponse = await this.paymentService.createClientToken(this.customer.ID, dwollaRequest);
    if (tokenResponse.isAnyError) {
      return;
    }
    let clientToken = tokenResponse.data;

    const response = await this.dwollaCustomService.addFundingSource(
      this.formGroup.get('name')?.value,
      this.formGroup.get('type')?.value.toLowerCase().replace(' ', ''),
      this.formGroup.get('routingNumber')?.value,
      this.formGroup.get('accountNumber')?.value,
      this.customer.ID,
      clientToken
    );

    if (response.ok) {
      this.modalCtrl.dismiss(true);
    } else {
      this.errorService.showError(response.status.toString(), 15, response.statusText);
    }
  }
}
