<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Accept Terms</ion-title>
  </ion-toolbar>
</ion-header>


<ion-content>
  <div id="contract-container" class="ion-padding-horizontal">
    <p class="no-top-margin"><b>Last Updated: October 3rd, 2023</b></p>
    <h2>Eventlink Terms and Conditions Of Service</h2>
    <p>This website, eventlink.com (the "Service"), is a service provided by SDI Innovations, Inc. (&ldquo;SDI&rdquo;) d/b/a EventLink.</p>
    <h2>User Agreement</h2>
    <p><strong>Definitions</strong></p>
    <p>The following terms shall have the following meanings in this Agreement:</p>
    <ul>
      <li>"Clients" and/or "Account Owners" are parties who have contracted with SDI to use the SDI eventlink.com system to schedule, pay, and/or organize officiating services, collect fees, sell official merchandise, and sell event tickets.</li>
      <li>"Authorized User" is a user that a Client authorizes to conduct business on its behalf.</li>
      <li>"IRS" means Internal Revenue Service.</li>
      <li>"State Taxing Authority" means any state, or quasi state, agency which requires reporting of payments to independent contractors for income taxing purposes.</li>
      <li>"Tax Reporting Services" means reporting to the IRS and/or State Taxing Authority based upon information provided by Clients, Users, and/or third-party vendors used by SDI.</li>
      <li>"Payment Services" means payment services provided by SDI or its affiliates to its Clients and Users for officiating services. The Payment Services are also deemed Services and are incorporated within its meaning.</li>
      <li>"Services" means the services which SDI provides to each User as part of their use of the SDI system and the eventlink.com website. Services are determined on an individual basis based upon the type of Services requested by the User.</li>
      <li>"CPII" means confidential personally identifiable information of Clients, Authorized Users and Users which is to be treated as confidential information. CPII is intended to include such items as social security numbers and passwords, but does not include information such as names, addresses, contact information, pictures, and other items which are voluntarily disclosed and/or uploaded on the unsecure portions of SDI website(s) by SDI, Client, Authorized Users and/or Users, which website(s) portions SDI, Client, Authorized Users and/or Users knew, or should have known (which includes, but is not limited to, if https:// was not part of the website address and/or they were not required to enter a password), were unsecure or publicly-available portions of the website(s).</li>
      <li ng-if="!main.official && !main.community">&ldquo;Dwolla&rdquo; means Dwolla, Inc, a third party service provider which provides Payment Services for Eventlink Pay.</li>
      <li ng-if="!main.official  && !main.community">&ldquo;Stripe&rdquo; means Stripe, Inc, a third party service provider which provides Payment Services for Eventlink Tickets and Kiosk.</li>
      <li ng-if="!main.official && !main.community">&ldquo;Payrix&rdquo; means Payrix, Inc, a third party service provider which provides Payment Services for Eventlink Registrations.</li>
      <li>&ldquo;Funding Source&rdquo; means a financial institution account linked to either a Receive Only User that is used to receive transfers or a Client User that is used to make transfers. A Funding Source must be a Bank Account.</li>
      <li>&ldquo;Bank Account&rdquo; means bank or credit union account at a state or federally-chartered institution.</li>
    </ul>
    <p><strong>General Terms</strong></p>
    <ul>
      <li>You must provide your full legal name, a valid email address, and any other information required in order to complete the signup process.</li>
      <li>You are responsible for providing the correct information to SDI for use by SDI to provide Services to its clients and to you. Failure to do so may result in liability.</li>
      <li>A single username and password ("login") may only be used by the one person to who it was assigned by SDI and that login is not assignable to any other party. A login may only be used by one person and that login is not assignable to any other person. Your username and password is so that you may access, utilize or otherwise employ the Services. Your login credentials are to be kept secret and confidential. You are responsible for keeping such username and password confidential and not to provide that information for any other party to use. Secure this information as you would any other confidential information.</li>
      <li>You are solely responsible and liable for all uses made by you of the Services or for failure to properly secure, and keep secure, your username and passwords (outside of access obtained solely through the eventlink.com site by malicious third parties or unauthorized use by SDI). If you become aware of any unauthorized activity or possible unauthorized activity, then you are responsible for immediately notifying SDI that you have been impacted by such. Failure to promptly notify SDI upon becoming aware of unauthorized activity or possible unauthorized activity may adversely impact you. You are also responsible for immediately changing your password. If you are not able to change your password, you must advise SDI of that issue immediately and work with SDI to change that password.</li>
      <li>You are responsible for keeping your password secure. DO NOT PROVIDE YOUR PASSWORD TO ANYONE ELSE.</li>
      <li>You are responsible for keeping your password secure. SDI is not liable, and you hereby hold SDI harmless, for any loss, claim, or damage from your login not being kept secure.</li>
      <li>You are responsible for all activity and content (data) that is uploaded under your login and/or Service account. You are responsible for the use of the username and password issued on your behalf. If activity and/or content is uploaded without your knowledge, you must report that activity and/or content to SDI immediately.</li>
      <li>You are responsible for each and every access, use or employment of the Services that occurs in conjunction with your login credentials. SDI is authorized to accept the username and passwords as conclusive evidence that you have accessed, utilized, or otherwise employed the Services, and not an unauthorized third party unless otherwise informed by you and SDI having a reasonable time to freeze your account.</li>
      <li>You hold SDI harmless for any downtime of your login credentials during which time SDI is investigating any potential unauthorized activity or possible unauthorized activity and for any reasonable downtime as a result of system tests, audits and/or upgrades. You are responsible and liable for all uses made by you or for your failure to properly secure, and keep secure, your login information.</li>
      <li>A breach or violation of any of the Account Terms as determined by the sole discretion of SDI may result in an immediate termination of your services and liability for actual, consequential, punitive, implied, and/or future damages.</li>
      <li>You are solely responsible for the content of any communications by you with third parties, including any communications about, concerning or through the Services.</li>
      <li>You agree that Services may be suspended by SDI for any violation of any term or condition herein or during any investigation of a violation, even if such violation is not found.</li>
      <li>You agree to indemnify SDI for any defense of claim and/or damages, whether through the court system, alternative dispute resolution, or by settlement, which SDI incurs as a result of your breach or violation of any of the Terms and Conditions herein.</li>
      <li>SDI reserves the right to modify or terminate the Service for any reason, without notice at any time.</li>
      <li>SDI reserves the right to refuse service to anyone for any reason at any time.</li>
      <li>Your use of the Service is at your sole risk and you hold SDI harmless for the same. The Service is provided on an "as is" and "as available" basis without any warranty or condition, express or implied.</li>
      <li>You understand that SDI utilizes third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Services. You hereby hold SDI harmless for any action or inaction by any third-party vendor used by SDI.</li>
      <li>Receive Only Users expressly authorize SDI&rsquo;s Payment Service provider<span ng-if="!main.official && !main.community">, Dwolla,</span> to originate credit transfers to Receive Only User&rsquo;s Bank Account.</li>

      <li ng-if="main.official || main.community">Eventlink uses Track Technologies, Inc. dba Abound (“Abound”) to provide tax or financial related services and are for informational purposes only and provided to you AS IS and without warranty or guarantee. Consult a tax or legal professional or provider before filing your annual tax returns. By using our service, you grant Eventlink and Abound the right, power, and authority to access and transmit your personal and financial information that you provide to Eventlink. You agree to your personal and financial information being transferred, stored, and processed by Abound in accordance with the <a href="https://www.withabound.com/legal" target="_blank">Abound Privacy Policy</a>.</li>
    </ul>
    <p><strong>Text Messaging</strong></p>
    <ul>
      <li>You understand that, should you choose to use text messaging (SMS) features as provided by the Service, you are responsible for any text messaging fees charged to you by your mobile phone carrier.</li>
      <li>In the event you change or deactivate your mobile telephone number, you will update your account information on eventlink.com within 48 hours to ensure that your messages are not sent to the person who acquires your old number. You agree that SDI is not responsible for any text message which is sent to your mobile telephone number and that it may take 48 hours or more to remove your mobile telephone number from the system.</li>
      <li>You will not transmit any CPII by text messaging</li>
    </ul>
    <p><strong>Unauthorized Activity/Uses</strong></p>
    <ul>
      <li>You must not upload, post, or transmit unsolicited email, SMS, or "spam" messages.</li>
      <li>You must not transmit or upload any worms or viruses or any code of a destructive nature, whether known or unknown to you.</li>
      <li>You may not use the Service for any illegal or unauthorized purpose.</li>
      <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission by SDI. You will not disclose or discuss any other party's information on the website with any person other than that person directly or SDI.</li>
      <li>You must be 18 years of age or older to use the Service (unless you must be older as required by the jurisdiction in which you reside and/or which you login to access the Services.) You are responsible for knowing the laws in your jurisdiction.</li>
      <li>You agree that you will not attempt to, by yourself or requested of others, enter restricted areas of SDI computer system or perform functions that you are not authorized to perform by SDI. SDI may, without notice, suspend your or any other party's access to the Services by deactivating the login if SDI suspects that you, or any other party using your login or Administrative Credentials (see account owners section), are obtaining unauthorized access to SDI other systems or information, or are using logins or Administrative Credentials in any other inappropriate or unauthorized manner. These suspensions will be for such periods of time as SDI may determine is necessary to permit the thorough investigation of such suspended activity. SDI may terminate the Services immediately without notice it if is determined that you, or any other party(ies), have undertaken such unauthorized activity or if such unauthorized activity cannot be reasonably explained.</li>
      <li>You shall not manipulate or otherwise attempt to add, delete, or alter information on the system for the purpose to defraud, deceive or otherwise mislead, whether by intention or not, SDI, any Account Owner, any Authorized User, any other User, any third party provider, or any federal, state or municipal authority. Notwithstanding any other provisions herein, any violation of this provision shall result in you being liable to any and/or all of these parties for damages, including, but not limited to, attorneys fees, court costs, actual damages, incidental damages, punitive damages, and damage to its reputation as a result of your actions.</li>
      <li>You will not, directly or indirectly, send, submit, post, receive or otherwise facilitate, or in any way use, or permit to be used, the Services to send, submit, post, receive or otherwise facilitate the transmission of any materials that are unlawful, offensive, libelous, defamatory, abusive, obscene, profane, sexually explicit, threatening, materials containing blatant expressions of bigotry, racism or hate, or otherwise objectionable or violate any party's intellectual property or these Terms and Conditions of Service. You must comply with all state, provincial, federal and local laws (including, but not limited to export/import laws and laws relating to privacy, obscenity, copyright, trademark, confidential information, trade secret, libel, slander or defamation). Although SDI is not responsible for any such communications, we may delete any such communications of which we become aware, at any time without notice to you or any other party. You also acknowledge and affirm that SDI may provide any such information, in our sole and absolute discretion, to local, state, or federal law enforcement or other party for any reason, without your permission.</li>
      <li>You will not modify any other user's information or use another user's login information to view any non-public data.</li>
      <li>You will not use the username of any other User for any reason. (This is not applicable to Authorized User(s) and/or Account Owner(s) who create a username for a User, but only in that limited circumstance and only as it relates to the initial creation of a username, not thereafter.)</li>
      <li>You will not knowingly use the password of any other User for any reason, even with said User&rsquo;s permission.</li>
      <li>You will not view or otherwise attempt to gain access to any User&rsquo;s CPII except for your own.</li>
      <li>You will not provide incorrect information or alter information which might cause SDI and/or Client to provide incorrect, false or fraudulent information to any federal, state, county, municipal, or other government or quasi-government agency, including, but not limited to, the IRS or any State Taxing Authority.</li>
      <li>This list contains examples of unauthorized activities/uses and is not exhaustive or complete. If you are concerned that a proposed activity is not an authorized use, please contact SDI for further guidance prior to taking any action. If you are an Authorized User, you also will need to contact your Account Owner to discuss your proposed activity (see Account Owner section).</li>
    </ul>
    <p><strong>Content and Information on Site</strong></p>
    <ul>
      <li>You must not store sensitive personally identifiable information on SDI servers unless a Client who is contracting with you for officiating services is utilizing the Tax Reporting Services or the Payment Services through SDI and only on secure areas of the site. Please review our privacy policy regarding what information is and is not stored on SDI&rsquo;s servers. "Sensitive personally identifiable information", or "CPII" includes any information which if lost, compromised, or disclosed without authorization, could result in substantial harm, embarrassment, inconvenience, or unfairness to an individual. This includes, but is not limited to, Federal Identification number, Social Security number, Social Insurance number, driver's license number, passport number, national identification number, national identity number, or national insurance number.</li>
      <li>If you are being paid by a Client who utilizes the Tax Reporting Services or the Payment Services, then you will be requested to provide CPII in secure areas of the site. You are not required to provide any CPII to utilize the officiating scheduling services, but you will be required to provide information to complete a Form W-9 if you wish to be paid through the SDI&rsquo;s Payment Services. A Client may directly request that you provide CPII so that they can utilize the Tax Reporting Services and/or the Payment Services or so they can properly report information as required to the Internal Revenue Service and/or State Taxing Authority(ies).</li>
      <li>You understand that your content may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.</li>
      <li>You acknowledge that most information shared or communicated during the use of the Services occurs in an unsecured environment, is not treated as confidential (except as otherwise noted),and may be intercepted and read by others. You agree with respect to any information provided by you to us that: (i) SDI has no obligation concerning such information; (ii) the information is non-confidential; (iii) SDI may use, disclose, distribute or copy the information and may use any ideas, concepts or know-how contained in the information for any purpose; and (iv) the information is truthful and disclosure of the information does not violate the legal rights of others. You acknowledge that by making your content public and using the Service, you agree to allow others to view your content.</li>
      <li>Unless required otherwise by law, all information (except for password and Administrative Credentials) exchanged between you and SDI will be considered non-confidential, unless it is CPII. CPII should only be added to secure areas of the site. CPII should not ever be transmitted by User by email or text messaging.</li>
      <li ng-if="!main.official && !main.community">Excluding Dwolla, Stripe, and Payrix, SDI and Clients are not responsible for third party vendors/providers, except to the extent that said party knew or should have reasonably known of an act or omission of said third party provider/vendor and failed to take action or notify the User. You hereby hold SDI and/or Client harmless for acts of third party providers/vendors.</li>
      <li>You acknowledge that while SDI and/or Clients are required to implement policies and procedures, consistent with applicable law and industry standards for like businesses, to protect your CPII from unauthorized access, that, even if such policies and procedures are followed implicitly, said policies and procedures may not prevent all breaches of security. SDI and/or Clients shall not be deemed responsible for security breaches which were outside its control if all reasonable measures were taken to prevent such breach. This shall not impact the individual liability of the person that actually accessed any CPII in an unauthorized manner, including if such person was an Authorized User or principal, employee, contractor, or party controlled by SDI and/or Client.</li>
    </ul>
    <p><strong>User Participation in Dwolla Payment Services</strong></p>
    <p ng-if="!main.official && !main.community">SDI partners with Dwolla, a trusted partner of SDI that makes transfers between bank accounts easy. Dwolla is a powerful payments platform that securely connects bank or credit union accounts to enable the transfer of money for any individual or organization in the U.S. By leveraging Dwolla&rsquo;s Access API, we are providing an integrated payments experience for seamless funds transfers for our customers, while reducing the headaches associated with fraud, exposure of sensitive financial data, and complications of using third-party processors.</p>
    <p ng-if="!main.official && !main.community">In order to use the payment functionality of EventLink, you must open a &ldquo;Access API&rdquo; account provided by Dwolla, Inc. and you must accept the Dwolla <a target="_blank" href="https://www.dwolla.com/legal/tos/">Terms of Service</a> and <a target="_blank" href="https://www.dwolla.com/legal/privacy/">Privacy Policy</a>. Any funds held in the Dwolla account are held by Dwolla&rsquo;s financial institution partners as set out in the Dwolla Terms of Service. You authorize SDI to share your identity and account data with Dwolla for the purposes of opening and supporting your Dwolla account, and you are responsible for the accuracy and completeness of that data. You understand that you will access and manage your Dwolla account through EventLink and Dwolla account notifications will be sent by SDI not Dwolla. SDI will provide customer support for your Dwolla account activity, and can be reached at <a target="_blank" href="mailto:help@eventlink.com">help&#64;eventlink.com</a> or (866) 330-7710.</p>
    <ul>
      <li>If you participate in the Payment Services which are offered by SDI, then you are required to provide CPII within secure areas of the site.</li>
      <li ng-if="main.official || main.community">If you register to create an account to receive payments via our application, you expressly authorize SDI service provider, Dwolla, Inc. to originate credit transfers to your financial institution account. You must be at least 13 years old and obtain parental permission if under 18 to receive funds. You authorize SDI to collect and share with Dwolla your personal information including full name, email address and financial information, and you are responsible for the accuracy and completeness of that data. Dwolla's Privacy Policy is available <a target="_blank" href="https://www.dwolla.com/legal/privacy/">here</a>.</li>
      <li>SDI is not responsible for the accuracy of any information provided by a Client or Authorized User for any Payment unless said party is directly and specifically aware of such inaccuracy.</li>
      <li>No User of any SDI service is required to provide CPII to SDI for Payment Services. Failure of User to participate in the Payment Services shall prevent SDI from providing those Payment Services to User and Client. In such case, the Client may be responsible for obtaining such information directly from said User.</li>
      <li>You are responsible for the accuracy of any information provided to SDI and/or Client and providing such information in a timely fashion.</li>
      <li>SDI is not responsible for the accuracy of any information provided to it by Client.</li>
      <li>If there is a dispute of a payment amount between SDI or Client and you, then you shall work with SDI and/or Client to resolve such payment dispute.</li>
      <li ng-if="!main.official && !main.community">A $1 transaction fee will be deducted from your Balance Account per transaction.</li>
    </ul>
    <p><strong>Eventlink Sites User Content Agreement</strong></p>
    <ul>
      <li>Any content, information, material or data, including logos and mascots, that you submit or post via the Eventlink Sites is referred to in these Terms as “User Content.” You retain all copyright to all original User Content you submit to Eventlink Sites. You grant us, and anyone authorized by us (including school and school booster clubs), a royalty-free, perpetual, irrevocable, non-exclusive, unrestricted, worldwide license to use, copy, modify, transmit, exploit, create derivative works from, distribute, and/or publicly perform or display such material, in whole or in part, in any manner or medium you have in the User Content, in any media known now or in the future. The foregoing grant includes the right to exploit any proprietary rights in such posting or submission, including, but not limited to, rights under copyright, trademark or patent laws that exist in any relevant jurisdiction. Also, in connection with the exercise of these rights, you grant us, and anyone authorized by us, the right to identify you as the author of any of your postings or submissions by name, email address or screen name, as we deem appropriate. You understand that the technical processing and transmission of Eventlink Sites including content submitted by you, may involve transmissions over various networks, and may involve changes to the content to conform and adapt it to technical requirements of connecting networks or devices.</li>
      <li>You acknowledge and agree that you will not post on Eventlink Sites any content for which you do not have the clear right to do so and to grant the rights granted above. You further acknowledge and agree that you are solely responsible and liable for any content that posted on Eventlink Sites and for any infringement or other liability that may result. You represent and warrant that <ul>
        <li>(i) all User Content is either wholly original to you, or all third party rights therein have been fully cleared for use as contemplated by these Terms, and that you have the right to grant the rights granted herein without violating the rights of any third party;</li>
        <li>(ii) the User Content and the posting thereof on Eventlink Sites does and will not, in any way, violate or breach any of the Terms, the Privacy Policy,  or violate or infringe upon the rights of any third party, and </li>
        <li>(iii) SDI Innovations shall not in any circumstance be required to pay or incur any sums to any person or entity as a result of its use or exploitation of the User Content unless otherwise agreed by SDI.</li>
      </ul></li>
      <li>Without limiting the foregoing, SDI and the respective school (including booster clubs), school employee, school organization, or site administrators reserves the right to use the User Content as it deems appropriate, including, without limitation, deleting, rejecting, or refusing to post it.</li>
      <li>Your submission of User Content is subject to the terms our User Agreement. SDI and the respective school, school employee, school organization, or site administrators shall not be obligated to enforce any form of attribution by third parties and shall not be responsible for any third party use of User Content.</li>
      <li>By providing content to Eventlink Sites, you hereby declare that <ul>
        <li>(a) you have no objection to the publication, use, modification, or deletion and exploitation of the User Content by SDI and the respective school, school employee, school organization, or site administrators;</li>
        <li>(b) you forever waive and agree not to claim or assert any entitlement to any and all moral rights of an author in any of the User Content; and </li>
        <li>(c) you forever release SDI and the respective school, school employee, school organization, or site administrators from any claims that you could otherwise assert against SDI by virtue of any such moral rights.</li>
      </ul></li>
      <li>SDI does not review the content posted by users on Eventlink Sites and is not responsible for such content. However, SDI reserves the right to delete, move or edit any content (including content posted in any interactive area) that it may determine, in its discretion, violates or may violate these Terms or is otherwise unacceptable. “Account Owners” shall be responsible for all content posted by “Account Owner”. SDI shall have the right, but not the obligation, to correct any errors or omissions in any content, as it may determine in its discretion.</li>
    </ul>
    <p>
      Account Owners SHALL BE SOLELY RESPONSIBLE FOR CONTENT PLACED ON THE CUSTOMER SITE OR ANY OF SDI’ WEBSITES BY Account Owner. Account owner WARRANTS THAT IT HAS PROPER AUTHORIZATION FOR PLACEMENT OF ALL CONTENT ON THE CUSTOMER SITE OR ANY OF Eventlink Sites. Account Owner MAY POST ON THE CUSTOMER SITE ANY CONTENT OWNED BY Account Owner, CONTENT FOR WHICH Account Owner HAS RECEIVED EXPRESS PERMISSION FROM THE OWNER, AND CONTENT IN THE PUBLIC DOMAIN. Account Owner ASSUMES ALL RIGHT AND RESPONSIBILITY FOR DETERMINING WHETHER ANY CONTENT IS IN THE PUBLIC DOMAIN. Account Owner GRANTS TO SDI, “Eventlink LLC.,” THE RIGHT TO EDIT, COPY, PUBLISH, DISTRIBUTE, TRANSLATE AND OTHERWISE USE IN ANY MEDIUM AND FOR ANY PURPOSE ANY CONTENT THAT CUSTOMER PLACES ON THE CUSTOMER SITE OR ANY SDI, Eventlink Sites, WITHOUT COMPENSATION TO CUSTOMER.
    </p>
    <p><strong>Indemnification, Disclaimer of Warranties, and Limitation on Damages</strong></p>
    <ul>
      <li>You agree to indemnify and hold harmless SDI from and against any claims, demands or causes of action whatsoever, and shall defend or settle all claims and actions brought against SDI by third parties arising out of or related to your use of the Services and any third party access or use of same using your login or Administrative Credentials (see account owner section), howsoever such claim or action shall have occurred or arose. In addition to the above, you are also liable for SDI attorney&rsquo;s fees and court costs in connection with such claim or action.</li>
      <li>SDI does not warrant in any manner, either express or implied, that (i) the Service will meet your specific requirements, (ii) the Service will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the Service will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you through the Service will meet your expectations, and (v) any errors in the Service will be corrected.</li>
      <li>Except as otherwise explicitly set out herein, SDI expressly disclaims any and all representations, warranties, and conditions concerning the Services, including any and all representations, warranties or conditions of design, merchantability or fitness for any particular purpose, non-infringement, or performance and any and all representations, warranties, or conditions that might otherwise arise during the course of dealing, usage or trade and those which may and those which may be implied by law. This Terms and Conditions of Service sets out the entire extent of all representations, warranties, and/or conditions in respect of the Services and no agent of SDI is authorized to alter the same. All alternations shall be in writing and signed by the expressly and specifically authorized officer of SDI. You assume all responsibility and risk for use of the Services.</li>
      <li>In no event shall SDI or any of its managers, members, employees, contractors or agents be liable for any general, incidental, direct, indirect, punitive, exemplary, consequential, special, or other damages whatsoever (including damages for loss of profits, interruption, loss of business information or any other pecuniary loss) in connection with any claim, loss, damage, action, suit or other proceeding arising under or out of any use of the Services including, without limitation, use of, reliance upon, access to, or exploitation of the Services, or any part hereof, and/or resulting from: (i) the use or the inability to use the Service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the Service; (v) or any other matter relating to the Service or any rights granted to you hereunder, even if SDI has been advised of the possibility of such damages, whether the action is based on contract, tort (including negligence), infringement of intellectual property rights, or otherwise. The consideration being paid for the Services hereunder does not include any consideration for SDI to assume any risks beyond those expressly assumed herein and, if any such risks were to be assumed by SDI, SDI would not have provided the Services to you without charging substantially higher fees.</li>
      <li>Notwithstanding any other provision contained herein, SDI and any of its managers', members', employees', contractors' or agents' total maximum aggregate collective liability under this Agreement and the license and use of the Services under any and all circumstances, arising in any manner whatsoever, shall be limited to the greater of: (1) five dollars ($5.00), or (2) the Service fees actually paid by you to SDI for Services in the one (1) year period immediately preceding the final act or event which gave rise to such liability.</li>
    </ul>
    <p><strong>Waiver and Legal Provisions</strong></p>
    <ul>
      <li>The failure of SDI to exercise or enforce any right or provision of the Terms and Conditions of Service shall not constitute a waiver of such right or provision.</li>
      <li>This Terms and Conditions of Service constitutes the entire agreement between you and SDI and govern your use of the Service, superseding any prior agreements between you and SDI (including, but not limited to, any prior versions of the Terms and Conditions of Service) and shall be read in conjunction with any other written agreement between the parties; however, the Terms and Conditions of Service shall control if there is any discrepancy, even if said agreement is executed later than the effective date of the Terms and Conditions of Service.</li>
      <li>This Terms and Conditions of Service shall be construed in accordance with and governed by the laws, other than choice of laws, of the State of Indiana. You agree to, short of a settlement, file suit in the courts of Tippecanoe County, Indiana, or the United States District Court for the Northern District of Indiana in Lafayette, Indiana.</li>
      <li>If any provision of these Terms and Conditions of Service shall be determined to be invalid, void, or illegal, such provision shall be construed and amended in a manner that would permit its enforcement, but in no event shall such provision affect, impair, or invalidate any other provision hereof. For any provision severed there will be deemed substituted a like provision to accomplish the intent of the parties as closely as possible to the provision as drafted, as determined by any court or arbitrator having jurisdiction over any relevant proceeding, to the extent permitted by the applicable law.</li>
      <li>If SDI has to enforce any of the provisions of these Terms and Conditions against you or suffers any damage (whether general, incidental, direct, indirect, punitive, exemplary, consequential, special, or other damages whatsoever (including damages for loss of profits, interruption, loss of business information or any other pecuniary loss)) as a result of your violation of any provision in said Terms and Conditions, then you are responsible for all costs of enforcement and/or any damages, including, but not limited to, attorneys fees and court costs.</li>
      <li>You acknowledge that you are not in partnership with, or an employee or agent of, SDI and all action taken by you is as an individual, independent of SDI. Neither party has the authority, actual, apparent or inferred, to bind the other.</li>
    </ul>
    <p><strong>Assignment and Accounting Information</strong></p>
    <ul>
      <li>SDI Innovations reserves the right to assign this User Agreement or any part thereof without restrictions.</li>
      <li>SDI Innovations has no obligation to retain account information upon termination/cancelation of an account.</li>
      <li>Your obligations under the Terms and Conditions of Service will survive the termination or cancellation of the use of Services, regardless of the cause or who terminates or cancels the Service.</li>
      <li>You will not permit the Services to be subject to any timesharing, services bureau, subscription services, or rental activities.</li>
      <li ng-if="!main.official && !main.community">SDI currently uses Dwolla, Stripe, and Payrix to facilitate payment between schools, officials, fans, eventgoers, and community members. Dwolla, Stripe, and Payrix are third party vendors and your use of such third party services is subject to the limitations herein.</li>
    </ul>
    <p><strong>Support</strong></p>
    <ul>
      <li>All accounts include access to Email support. "Email support" means the ability to make requests for technical support assistance by email or web-based help desk at any time (with reasonable efforts by SDI to respond within one business day) concerning the use of Service. Support can be reached at this e-mail address help&#64;eventlink.com.</li>
      <li>All support will be provided in accordance with SDI standard practices, procedures and policies.</li>
    </ul>
    <p><strong>Modifications to the Service and Prices</strong></p>
    <ul>
      <li>SDI reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.</li>
      <li>Prices of all Services, including, but not limited to, monthly subscription plan fees to the Service, are subject to change upon 30 days' notice from SDI. Such notice may be provided at any time by posting the changes to the Service site or the Service itself.</li>
      <li>SDI shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service or for change, addition, or deletion of any third party vendor or their service from the eventlink.com website.</li>
    </ul>
    <p><strong>Changes to the User Agreement</strong></p>
    <ul>
      <li>SDI reserves the right to update, amend, add to, delete, and otherwise modify the User Agreement and/or Terms and Conditions of Service from time to time without notice and at its sole discretion. Use of the Service at any time after such modification to said User Agreement and/or Terms and Conditions of Service shall constitute your consent to such changes.</li>
      <li>Any new features or tools which are added to or old features or tools which are modified or deleted from the current Service shall be also subject to the Terms and Conditions of Service. Continued use of the Service after any such additions, deletions, or modifications shall constitute your consent to such changes.</li>
      <li>You can review the most current version of the User Agreement and Terms of Service at any time or use the Terms of Service link provided on the bottom of every screen. SDI Innovations reserves the right to update and change the User Agreement by posting updates and changes to the eventlink.com website.</li>
      <li><strong>You agree to be bound to any changes to Terms and Conditions of Service when you use the Services after any such modification is posted. You are advised to check the Terms and Conditions of Service from time to time for any updates or changes that may impact you.</strong></li>
    </ul>
    <p><strong>Copyrights and Intellectual Property</strong></p>
    <ul>
      <li>This Agreement does not transfer from SDI to you any SDI or third party intellectual property, and all right, title and interest in and to such property will remain (as between the parties) solely with SDI. SDI, SDI Innovations, eventlink.com, the eventlink.com logo, design, and/or mark, and all other trademarks, service marks, graphics and logos used in connection with eventlink.com or the respective websites are intellectual property of SDI or SDI&rsquo;s licensors. Other trademarks, service marks, graphics and logos used in connection with the website may be the intellectual property of other third parties. Your use of the Service grants you no right or license to reproduce or otherwise use any SDI or third-party intellectual property. Your misuse of the intellectual property displayed on any SDI associated website, or any other content on said website is strictly prohibited. You are also advised that SDI will aggressively enforce its intellectual property rights to the fullest extent of the law.</li>
      <li>You will not create derivatives from or modify, decompile or reverse engineer the software which is used to provide the Services or do anything that will reveal or generate the source code of said software. Any such act will be deemed to constitute a trespass on the rights, titles and interests of SDI and its vendors and/or suppliers.</li>
      <li>You do not have any right, title or ownership interest in the Services, any software, or documentation or information on the eventlink.com website, except for the limited right to use the Services subject to the Terms and Conditions of Service.</li>
      <li>The look and feel of the Service is copyright SDI. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML/CSS or visual design elements without express written permission from SDI Innovations.</li>
    </ul>
    <p><strong>Privacy Policy</strong></p>
    <ul>
      <li>We do not sell or share our subscriber lists. Except as authorized by the subscriber in writing or as required by law or in compliance with reasonable requests from law enforcement, we will not reveal the name or company of any subscriber to third parties. Our full privacy policy can be found on our website.</li>
      <li>You will not use any information on the website(s) to produce a list or other database of Account Owners, Authorized Users, and/or other Users of the SDI Services for any purpose (though Account Owners may be allowed to produce internal lists subject to Account Owner Agreement) and never for the purpose of providing it to third parties for marketing or other uses in violation of SDI&rsquo;s privacy policy.</li>
    </ul>
    <p><strong>Notices</strong></p>
    <ul>
      <li>If you need to provide notice to SDI, then such notice shall be sent to the address listed on the eventlink.com website.</li>
      <li>If SDI is to provide you notice, it will provide said notice to the email and/or mailing address you provided to SDI in your user profile. It is your responsibility to keep such information current to insure the proper delivery of such notices.</li>
      <li>Any notice by one party shall be valid and shall be deemed given, if delivered personally, upon delivery thereof, or if mailed by certified mail, return receipt requested, three (3) days after depositing with the United States Postal Service, or if by nationally recognized overnight courier, one (1) day after depositing with nationally recognized overnight courier when addressed in compliance with this section.</li>
      <li>If you have chosen to receive email notices, then such notice shall be deemed given when sent to the email address provided to SDI.</li>
    </ul>
    <p><strong>Questions</strong></p>
    <ul>
      <li>Questions about the Terms of Service should be sent to the support email address (<a href="mailto:help@eventlink.com">help&#64;eventlink.com</a>)</li>
    </ul>
    <p><strong>Account Owner Agreement</strong></p>
    <ul>
      <li>The Account Owner and any Authorized Users are subject to this User Agreement.</li>
    </ul>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="acceptTerms()"
          expand="block">
          <ion-text> I Accept </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
