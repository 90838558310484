// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { LegacyApiService } from "../bases/legacy-api.service";
import { Guid, DateTime, DateOnly } from "../models/ApiTypes";
import { dtoEventLongForm } from "../models/legacy/dtoEventLongForm";
import { dtoEventSearch } from "../models/legacy/dtoEventSearch";
import { dtoEventCalendarSearch } from "../models/legacy/dtoEventCalendarSearch";
import { dtoEventShortForm } from "../models/legacy/dtoEventShortForm";
import { dtoWidgetForm } from "../models/legacy/dtoWidgetForm";
import { dtoEventOrganizationExportForm } from "../models/legacy/dtoEventOrganizationExportForm";
import { FacilityIDsRequest } from "../models/legacy/FacilityIDsRequest";
import { dtoEventCreateUpdate } from "../models/legacy/dtoEventCreateUpdate";
import { dtoEventChangeStatus } from "../models/legacy/dtoEventChangeStatus";

@Injectable({providedIn: 'root'})
export class EventLegacyApiService {
    private readonly module = "Event";

    constructor(private legacyApi: LegacyApiService) { }

    /** Find an event by ID
     * @param id Event ID
     */
    find(id: Guid) {
        return this.legacyApi.get<dtoEventLongForm | undefined>(this.module, "Find", { id });
    }

    /** Search for events, you can filter by a number of different parameters.
     * @param title 
     * @param start 
     * @param end 
     * @param tz Timezone
     * @param organization 
     * @param calendar 
     * @param location 
     * @param teams String of teams seperated by commas
     * @param opponent 
     * @param sport 
     * @param level 
     */
    search(title: string | undefined, start: DateTime, end: DateTime, tz: string, organization: Guid | undefined, calendar: Guid | undefined, location: Guid | undefined, teams: string | undefined, opponent: Guid | undefined, sport: Guid | undefined, level: Guid | undefined) {
        return this.legacyApi.get<dtoEventSearch[] | undefined>(this.module, "Search", { title, start, end, tz, organization, calendar, location, teams, opponent, sport, level });
    }

    /** Get all events associated with multiple calendars by search criteria in the request body
     * @param body 
     */
    getByCalendarIDs(body: dtoEventCalendarSearch) {
        return this.legacyApi.post<dtoEventShortForm[] | undefined>(this.module, "GetByCalendarIDs", {  }, body);
    }

    /** Finds all events associated with a singular calendar ID
     * @param id Calendar ID
     * @param start 
     * @param end 
     * @param tz Timezone
     */
    getByCalendarID(id: Guid, start: DateTime, end: DateTime, tz: string) {
        return this.legacyApi.get<dtoEventShortForm[] | undefined>(this.module, "GetByCalendarID", { id, start, end, tz });
    }

    /** Retrieves an event and all data required to set up Event Widget
     * @param id Calendar ID
     * @param start 
     * @param end 
     * @param tz Timezone
     */
    getForEventWidget(id: Guid, start: DateTime, end: DateTime, tz: string) {
        return this.legacyApi.get<dtoWidgetForm | undefined>(this.module, "GetForEventWidget", { id, start, end, tz });
    }

    /** Retrieves events by calendar ID that also has a organization Token
     * @param id Calendar ID
     * @param token 
     * @param start 
     * @param end 
     * @param tz Timezone
     */
    getByCalendarIDWithToken(id: Guid, token: Guid, start: DateTime, end: DateTime, tz: string) {
        return this.legacyApi.get<dtoEventShortForm[] | undefined>(this.module, "GetByCalendarIDWithToken", { id, token, start, end, tz });
    }

    /** Retrieves events by multiple calendar IDs that also have organization Tokens
     * @param ids String of calendar IDs seperated by commas
     * @param token 
     * @param start 
     * @param end 
     * @param tz Timezone
     */
    getByCalendarIDsWithToken(ids: string, token: Guid, start: DateTime, end: DateTime, tz: string) {
        return this.legacyApi.get<dtoEventShortForm[] | undefined>(this.module, "GetByCalendarIDsWithToken", { ids, token, start, end, tz });
    }

    /** Make people use /api/external/Events/SearchByOrganization Retrieve events associated with an organization
     * @param token 
     */
    getByOrganization(token: Guid) {
        return this.legacyApi.get<dtoEventOrganizationExportForm[] | undefined>(this.module, "GetByOrganization", { token });
    }

    /** Retrieves all events associated with multiple facility IDs.
     * @param facilityIDs String of facility IDs seperated by commas
     * @param start 
     * @param end 
     * @param tz Timezone
     * @param body 
     */
    getByFacilityIDs(facilityIDs: string, start: DateTime, end: DateTime, tz: string, body: FacilityIDsRequest) {
        return this.legacyApi.post<dtoEventShortForm[] | undefined>(this.module, "GetByFacilityIDs", { facilityIDs, start, end, tz }, body);
    }

    /** Allows a facility manager to retrieve facility requests. Returns NoContent if user is not a facility manager.
     */
    getFacilityRequestsForManager() {
        return this.legacyApi.get<dtoEventLongForm[] | undefined>(this.module, "GetFacilityRequestsForManager", {  });
    }

    /** Creates a new event, must not be trying to add an event to a game calendar. If trying to add events to a practice, organization, or district calendars must be athletic only school. Returns conflict when there is event facility conflicts and double book is being forced.
     * @param start 
     * @param end 
     * @param tz 
     * @param force 
     * @param body 
     */
    create(start: DateOnly, end: DateOnly, tz: string, force: boolean | undefined, body: dtoEventCreateUpdate) {
        return this.legacyApi.post<dtoEventLongForm[] | undefined>(this.module, "Create", { start, end, tz, force }, body);
    }

    /** Update an event, can't update game events using this. If trying to add events to a practice, organization, or district calendars must be athletic only school. Returns conflict when there is a conflicting event at that facility.
     * @param start 
     * @param end 
     * @param tz 
     * @param force 
     * @param alterFor 
     * @param body 
     */
    update(start: DateOnly, end: DateOnly, tz: string, force: boolean | undefined, alterFor: string, body: dtoEventCreateUpdate) {
        return this.legacyApi.post<dtoEventLongForm[] | undefined>(this.module, "Update", { start, end, tz, force, alterFor }, body);
    }

    /** Delete an event by ID
     * @param id Event ID
     * @param start 
     * @param end 
     * @param tz 
     * @param alterFor 
     * @param alterForDate 
     */
    delete(id: Guid, start: DateOnly, end: DateOnly, tz: string, alterFor: string, alterForDate: DateOnly) {
        return this.legacyApi.post<dtoEventLongForm[] | undefined>(this.module, "Delete", { id, start, end, tz, alterFor, alterForDate });
    }

    /** Cancel an event, user must be a calendar admin, have Athletic Permissions with an active event to be canceled or lastly be a coach with permission to edit that specific calendar the event is on. Returns 400 if cancel note or refund reason is over 200 characters.
     * @param body 
     */
    cancel(body: dtoEventChangeStatus) {
        return this.legacyApi.post<void>(this.module, "Cancel", {  }, body);
    }

    /** Reschedule an event, user must be either have Athletic Permissions or be a calendar admin.
     * @param body 
     */
    reschedule(body: dtoEventChangeStatus) {
        return this.legacyApi.post<void>(this.module, "Reschedule", {  }, body);
    }

    /** Share an event with someone over email
     * @param id eventID
     * @param email 
     */
    shareEvent(id: Guid, email: string) {
        return this.legacyApi.post<void>(this.module, "ShareEvent", { id, email });
    }

    /** Accepts a facility request, user must be facility manager. Returns conflict when there is a conflicting event at that facility.
     * @param eventID 
     * @param force 
     * @param facilityID 
     * @param note 
     */
    acceptFacilityRequest(eventID: Guid, force: boolean | undefined, facilityID: Guid, note: string | undefined) {
        return this.legacyApi.post<dtoEventLongForm[] | undefined>(this.module, "AcceptFacilityRequest", { eventID, force, facilityID, note });
    }

    /** Rejects a facility request, user must be facility manager.
     * @param eventID 
     * @param facilityID 
     * @param note 
     */
    rejectFacilityRequest(eventID: Guid, facilityID: Guid, note: string | undefined) {
        return this.legacyApi.post<void>(this.module, "RejectFacilityRequest", { eventID, facilityID, note });
    }
}