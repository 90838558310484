import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AboundApiService } from 'src/app/core/services/api/v2/AboundApi.service';
import { UserLegacyApiService } from 'src/app/core/services/api/v1/UserLegacyApi.service';
import { dtoUserLongForm } from 'src/app/core/services/api/models/legacy/dtoUserLongForm';
import { DtoAboundDocumentAccessTokenRequest } from 'src/app/core/services/api/models/DtoAboundDocumentAccessTokenRequest';

@Component({
  selector: 'app-verify-password-tax',
  templateUrl: './verify-password-tax.component.html',
  styleUrls: ['./verify-password-tax.component.scss'],
})
export class VerifyPasswordTaxComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  formGroup: FormGroup;
  @Input() password: string;
  user: dtoUserLongForm = {
    ID: undefined,
    Username: undefined,
    FirstName: undefined,
    LastName: undefined,
    Address1: undefined,
    Address2: undefined,
    County: undefined,
    City: undefined,
    State: undefined,
    Zip: undefined,
    TimeZone: undefined,
    Latitude: undefined,
    Longitude: undefined,
    LastSignedIn: undefined,
    DefaultApplication: undefined,
    ContactPoints: undefined,
  };

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly fb: FormBuilder,
    private readonly aboundService: AboundApiService,
    private readonly userService: UserLegacyApiService
  ) {
    this.password = '';
    this.formGroup = this.fb.group({
      password: ['', [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
    const userResponse = await this.userService.find(this.context.UserID!);
    if (userResponse.isAnyError) {
      return;
    }
    this.user = userResponse.data!;
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async sendPassword() {
    let body: DtoAboundDocumentAccessTokenRequest = {
      Password: this.formGroup.get('password')?.value,
    };
    const response = await this.aboundService.createDocumentsTokenForCurrentUser(body);
    if (response.isAnyError) {
      return;
    }
    const token = response.data;
    this.modalCtrl.dismiss(token);
  }
}
