import { Directive, HostListener, OnInit, OnDestroy, Input, HostBinding } from '@angular/core';
import * as moment from 'moment';
import { int } from 'src/app/core/services/api/models/ApiTypes';
import { DtoStoredFilePublic } from 'src/app/core/services/api/models/DtoStoredFilePublic';
import { StoredFilesApiService } from 'src/app/core/services/api/v2/StoredFilesApi.service';

@Directive({
  selector: '[appElStoredFileSrc]',
})
export class ElStoredFileSrcDirective implements OnInit, OnDestroy {
  @Input() public storedFile!: DtoStoredFilePublic; 
  @HostBinding('src') fileSrc = '';

  timer: int;
  attempt: int;
  nextStoredFileSearch: moment.Moment;

  constructor(public readonly storedFilesApiService: StoredFilesApiService) {
    this.timer = 0;
    this.attempt = 0;
    this.nextStoredFileSearch = moment();
  }

  ngOnInit() {
    this.resetFromWatch();
  }

  @HostListener('storedFile') onLoad(
    value: DtoStoredFilePublic,
    oldValue: DtoStoredFilePublic
  ) {
    if (value && oldValue && value.ID === oldValue.ID) {
      return;
    }
    this.storedFile = value;
    this.resetFromWatch();
  }

  ngOnDestroy() {
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
  }

  resetFromWatch() {
    if (this.timer) {
      window.clearTimeout(this.timer);
    }

    if (this.storedFile?.ID) {
      this.attempt = 0; 
      this.doSearch();
    }
  }

  doSearch() {
    if (this.storedFile.BlobUrl) {
      this.setSrc(this.storedFile.BlobUrl);
    } else {
      if (this.attempt === 0) {
        this.setSrc('../../../assets/images/loading/loading.gif');
      }

      // Calculate next check time and reserve place in line
      // The first few checks can be pretty quick but after that we need to give it more time
      let minimumMilliseconds = this.attempt < 3 ? 1300 + this.attempt * 300 : 10000;
      let maximumAdditionalMilliseconds = this.attempt < 3 ? 2000 : 6000;
      let timeToWaitMilliseconds = minimumMilliseconds + Math.random() * maximumAdditionalMilliseconds;
      let nextSearchMoment = this.nextStoredFileSearch.add(timeToWaitMilliseconds, 'ms');
      if (nextSearchMoment.diff(moment()) < 0) {
        nextSearchMoment = moment().add(timeToWaitMilliseconds, 'ms');
      }
      this.nextStoredFileSearch = nextSearchMoment;

      let timeoutMilliseconds = this.nextStoredFileSearch.diff(moment());
      this.timer = window.setTimeout(this.checkFile.bind(this), timeoutMilliseconds);
    }

    this.attempt++;
  }

  setSrc(src: string) {
    this.fileSrc = src;
  }

  async checkFile() {
    const response = await this.storedFilesApiService.getByID(this.storedFile.ID);
    if (response.isAnyError) {
      return;
    }
    if (response.data) {
      let updated = response.data;
      // Apply updated info to our stored object
      if (updated.ID && updated.ID == this.storedFile.ID) {
        this.storedFile.BlobUrl = updated.BlobUrl;
        this.storedFile.CurrentStatus = updated.CurrentStatus;
        this.storedFile.ContentType = updated.ContentType;
      }
    }
    this.doSearch();
  }
}
