// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { int } from "../models/ApiTypes";
import { DtoStoredFilePublic } from "../models/DtoStoredFilePublic";

@Injectable({providedIn: 'root'})
export class StoredFilesApiService {
    private readonly controller = "StoredFiles";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param id 
     */
    getByID(id: int) {
        return this.v2Api.get<DtoStoredFilePublic>(this.controller, "GetByID", { id });
    }
}