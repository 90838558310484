<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()" expand="block">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Tax Profile</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-top">
  <div class="content-sticky-footer">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="search()"
          expand="block"
          [disabled]="!formGroup.valid">
          <ion-text> Search </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
