<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text>Cancel</ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Manage Schools</ion-title>
    <ion-buttons slot="end">
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-segment class="segment-header" [(ngModel)]="currentView" style="margin-top: 0">
  <ion-segment-button value="subscriptions" (click)="currentView = 'subscriptions'">Subscriptions
  </ion-segment-button>
  <ion-segment-button value="notifications" (click)="currentView = 'notifications'">Notifications
  </ion-segment-button>
</ion-segment>
<ion-content class="ion-padding-horizontal" style="--padding-top: 16px;">
  <div *ngIf="currentView === 'subscriptions'">
    <div class="ion-padding-bottom">
      <ion-item>
        <ion-input labelPlacement="stacked" placeholder="" [(ngModel)]="name">
          <div slot="label">Enter School name
            <ion-text color="danger">*</ion-text>
          </div>
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-select label-placement="stacked" [(ngModel)]="state">
          <div slot="label">(Optional) State</div>
          <ion-select-option *ngFor="let state of this.states" [value]="state.key">
            {{ state.label }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-input
          label-placement="stacked"
          type="number"
          id="zip"
          maxlength="5"
          placeholder="00000"
          [(ngModel)]="zip">
          <div slot="label">(Optional) Zip Code</div>
        </ion-input>
      </ion-item>
      <ion-button
        class="submit-button button-height"
        type="submit"
        (click)="this.searchSchools()"
        appPreventMultiClickEvents
        expand="block">
        <ion-text> SEARCH</ion-text>
      </ion-button>
    </div>
    <ion-text class="section-header" style="padding-bottom: 8px">Available</ion-text>
    <div *ngIf="searching">
      <ion-item class="school-padding">
        <ion-grid style="min-width: 100%">
          <ion-row>
            <ion-col style="max-width: 20%">
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
            </ion-col>
            <ion-col>
              <ion-row>
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </ion-row>
              <ion-row>
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </div>
    <div *ngIf="!searching">
      <ion-item *ngFor="let school of this.searchedSchools" class="school-padding">
        <ion-grid style="min-width: 100%">
          <ion-row>
            <ion-col style="max-width: 20%">
              <ion-icon
                class="first-element"
                #refEl
                name="ellipse-outline"
                size="large"
                (click)="addSchoolId(school, refEl)"/>&nbsp;
            </ion-col>
            <ion-col>
              <ion-row>
                <span class="second-element" style="text-transform: uppercase">{{school.SchoolName}}</span>
              </ion-row>
              <ion-row>
                <div class="second-element">{{school.City}}, {{school.State}}, {{school.Zip}}</div>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-row
        *ngIf=" this.searchedSchools.length === 0"
        class="ion-padding-bottom"
        style="margin-top: 5px; color: white"
      >No results. Try searching with different parameters.
      </ion-row>
    </div>
    <div *ngIf="firstLoading">
      <ion-item class="school-padding">
        <ion-grid style="min-width: 100%">
          <ion-row>
            <ion-col style="max-width: 20%">
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
            </ion-col>
            <ion-col>
              <ion-row>
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </ion-row>
              <ion-row>
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </div>
    <div *ngIf="!firstLoading"  style="padding-bottom: 15dvh" >
      <ion-text [ngStyle]="{'display': this.newSchools.length <= 0 ? 'none' : 'block'}">
        Subscriptions
      </ion-text>
      <ion-item *ngFor="let school of this.newSchools" class="school-padding">
        <ion-grid style="min-width: 100%">
          <ion-row>
            <ion-col style="max-width: 20%">
              <ion-icon
                class="first-element"
                #refEl
                name="checkmark-circle-outline"
                size="large"
                (click)="addSchoolId(school, refEl)"/>
            </ion-col>
            <ion-col>
              <ion-row>
                <span class="second-element" style="text-transform: uppercase">{{school.SchoolName}}</span>
              </ion-row>
              <ion-row>
                <div class="second-element">{{school.City}}, {{school.State}} {{school.Zip}}</div>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </div>
  </div>
  <div *ngIf="currentView === 'notifications'">
    <div style="padding-bottom: 15dvh">
      <ion-item *ngFor="let school of this.schoolNotificationMapping" class="school-padding">
        <ion-grid style="min-width: 100%">
          <ion-row>
            <ion-col>
              <span>{{school.organization.SchoolName}}</span>
            </ion-col>
            <ion-col>
              <ion-checkbox label-placement="stacked" [(ngModel)]="school.notification" [checked]="school.notification">
                Notification
              </ion-checkbox>
            </ion-col>
            <ion-col>
              <ion-checkbox label-placement="stacked" [(ngModel)]="school.dailySummary" [checked]="school.dailySummary">
                Daily Summary
              </ion-checkbox>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </div>
  </div>
</ion-content>

<div class="content-sticky">
  <div class="button-container">
    <div class="save-button-container">
      <ion-button
        class="save-btn button-height"
        type="submit"
        appPreventMultiClickEvents
        (preventMultiClicks)="save()"
        expand="block">
        Save
      </ion-button>
    </div>
  </div>
</div>
