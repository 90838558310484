
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Register Account</ion-title>
  </ion-toolbar>
</ion-header>


<ion-content>
  <div id="register-account-form" class="ion-padding-horizontal">
    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter your first name..."
          type="text"
          [(ngModel)]="customerData.FirstName">
          <div slot="label">First name <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter your last name..."
          type="text"
          [(ngModel)]="customerData.LastName">
          <div slot="label">Last name <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter your email..."
          type="text"
          [(ngModel)]="customerData.Email">
          <div slot="label">Email <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter your address..."
          type="text"
          [(ngModel)]="customerData.Address1">
          <div slot="label">Address 1</div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          type="text"
          [(ngModel)]="customerData.Address2">
          <div slot="label">Address 2</div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          type="text"
          [(ngModel)]="customerData.City">
          <div slot="label">City</div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-select label-placement="stacked" [(ngModel)]="customerData.State">
          <div slot="label">State</div>
          <ion-select-option *ngFor="let state of states" [value]="state.key">
            {{ state.label }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          type="text"
          [(ngModel)]="customerData.Zip">
          <div slot="label">Zip Code</div>
        </ion-input>
      </ion-item>
    </span>
  </div>

  <div class="content-sticky-footer bottom-bkp-09">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="saveAccount(customerData)"
          expand="block">
          <ion-text> Save </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
