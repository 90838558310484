import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-accept-privacy-policy',
  templateUrl: './accept-privacy-policy.component.html',
  styleUrls: ['./accept-privacy-policy.component.scss'],
})
export class AcceptPrivacyPolicyComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  @Input() applicationType!: string;

  constructor (
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
  ) {}

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  acceptTerms() {
    this.modalCtrl.dismiss('accept');
  }
}
