<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modalCtrl.dismiss()"> Close</ion-button>
    </ion-buttons>
    <ion-title>Event Details</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid *ngIf="foundEvent" class="ion-padding-horizontal">
    <ion-row *ngIf="foundEvent.Team">
      <h1>{{ foundEvent.Team.Title }}
        <span *ngIf="applicationTypeService.applicationType$.getValue() !== 'Officials' && isHomeEvent">(H)</span>
        <span
          *ngIf="applicationTypeService.applicationType$.getValue() !== 'Officials' && !isHomeEvent && foundEvent.ContractID">(A)</span>
      </h1>
    </ion-row>
    <ion-row *ngIf="foundEvent.Title && applicationTypeService.applicationType$.getValue() !== 'Officials'">
      <h3 size="">{{ foundEvent.Title }}</h3>
    </ion-row>
    <ion-row *ngIf="foundEvent.Location && applicationTypeService.applicationType$.getValue() === 'Officials'">
      <h3 size="">{{ foundEvent.Location}}</h3>
    </ion-row>
    <ion-row *ngIf="foundEvent.StartDateTime" class="top-padding"><span
      class="front-text">When: </span>{{ foundEvent.StartDateTime}}</ion-row>
    <ion-row *ngFor="let rule of foundEvent.RepeatRules" class="top-padding"><span
      class="front-text">Repeat: </span>{{rule.RepeatType}} <span
      *ngIf="rule.Values">{{returnRepeatValuesStatement(rule)}}</span></ion-row>
    <ion-row *ngIf="applicationTypeService.applicationType$.getValue() === 'Officials' && foundEvent.Title"
             class="top-padding"><span class="front-text">Event:</span> {{foundEvent.Title}}</ion-row>
    <ion-row
      *ngIf="applicationTypeService.applicationType$.getValue() !== 'Officials' && foundEvent.Calendar && foundEvent.Calendar.Title"
      class="top-padding"><span class="front-text">Calendar:</span> {{foundEvent.Calendar.Title}}</ion-row>
    <ion-row *ngIf="foundEvent.Location" class="top-padding"><span
      class="front-text">Location:</span> {{foundEvent.Location}}</ion-row>
    <ion-row *ngIf="foundEvent.Facilities && foundEvent.Facilities.length === 1 && foundEvent.Facilities[0]"
             class="top-padding"><span class="front-text">Facility: </span>{{ foundEvent.Facilities[0].Title }}
    </ion-row>
    <ion-row class="top-padding"
             *ngIf="applicationTypeService.applicationType$.getValue() === 'Officials' && foundEvent.LinkID">
      <span class="front-text" style="align-content: center;">Linked:</span> Yes
    </ion-row>
    <ion-row class="top-padding"
             *ngIf="applicationTypeService.applicationType$.getValue() === 'Officials' && !foundEvent.LinkID">
      <span class="front-text" style="align-content: center;">Linked:</span> No
    </ion-row>
    <ion-row *ngIf="hostingOrganization" class="top-padding"><span
      class="front-text">Host:</span> {{hostingOrganization.DisplayTitle ? hostingOrganization.DisplayTitle : hostingOrganization.Title}}
    </ion-row>
    <ion-row *ngIf="athleticDirector" class="top-padding"><span
      class="front-text">Host AD:</span> {{athleticDirector.User?.Name}}</ion-row>

    <ion-row *ngIf="foundEvent.Organization && foundEvent.Organization.Address1" class="top-padding"><span
      class="front-text">Address:</span> {{ foundEvent.Organization.Address1 }}</ion-row>
    <ion-row *ngIf="foundEvent.Opponents && foundEvent.Opponents.length === 1" class="top-padding"><span
      class="front-text">Opponent:</span> {{ foundEvent.Opponents[0].Title}}</ion-row>
    <ion-row *ngIf="foundEvent.EventType" class="top-padding"><span
      class="front-text">Type:</span> {{ foundEvent.EventType}}</ion-row>
    <div *ngIf="foundEvent.OfficialSlots && foundEvent.OfficialSlots > 0">
      <h4>Official Invitation Information</h4>
      <ion-row class="top-padding"><span
        class="front-text">Position:</span> {{ foundEvent.OfficialSlots[foundEvent.OfficialSlots.length - 1].Title }}
      </ion-row>
      <ion-row class="top-padding"><span
        class="front-text">Fee:</span> {{ foundEvent.OfficialSlots[foundEvent.OfficialSlots.length - 1].Fee | currency }}
      </ion-row>
      <ion-row class="top-padding">
        <span class="front-text">Invited By:</span>
        {{
        foundEvent.OfficialSlots[foundEvent.OfficialSlots.length - 1].Official
          ? foundEvent.OfficialSlots[foundEvent.OfficialSlots.length - 1].Official.User.Name
          : ''
        }}
      </ion-row>
    </div>
  </ion-grid>
  <ion-grid *ngIf="applicationTypeService.applicationType$.getValue() === 'Officials' && event.color !== '#E06B27'" class="sticky-footer">
    <ion-row>
        <ion-button  class="secondary-button"
          (click)="navigateTo('games')"
          onkeydown="navigateTo('games')"
        >
          <fa-icon icon="person-running"></fa-icon>
          Go to Games
        </ion-button
        >
    </ion-row>
  </ion-grid>
  <ion-grid *ngIf="applicationTypeService.applicationType$.getValue() === 'Staff' " class="sticky-footer">
    <ion-row>
      <ion-col>
        <ion-button class="button-height red-button">
          <ion-icon class="icon-padding-right" name="trash-outline" appPreventMultiClickEvents
                    (preventMultiClicks)="deleteEvent()"/>
          Delete Event
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button class="button-height blue-button">
          <ion-icon class="icon-padding-right" name="pencil" appPreventMultiClickEvents
                    (preventMultiClicks)="editDetails()"/>
          Edit
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button class="button-height red-button">
          <ion-icon size="large" name="close-outline" appPreventMultiClickEvents (preventMultiClicks)="cancelEvent()"/>
          Cancel Event
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button class="button-height blue-button">
          <ion-icon class="icon-padding-right" name="push-outline" appPreventMultiClickEvents
                    (preventMultiClicks)="extendEvent()"/>
          Extend
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
