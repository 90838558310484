import { Injectable, ErrorHandler } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private readonly logger: NGXLogger,
    private readonly toastController: ToastController
  ) {}

  async handleError(error: any): Promise<void> {
    var errorMessage =
      error.error && error.error.errors && error.error.errors._User.length > 0
        ? error.error.errors._User[0]
        : error.message;

    this.logger.error(error);

    if (!environment.production && errorMessage) {
      const toast = await this.toastController.create({ message: errorMessage, duration: 3500, color: 'danger' });
      await toast.present();
    }
  }
}
