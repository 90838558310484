import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentLegacyApiService } from 'src/app/core/services/api/v1/PaymentLegacyApi.service';
import { FundingSourceMicroDepositVerification } from 'src/app/core/services/api/models/legacy/FundingSourceMicroDepositVerification';
import { double } from 'src/app/core/services/api/models/ApiTypes';

@Component({
  selector: 'app-verify-micro-deposits',
  templateUrl: './verify-micro-deposits.component.html',
  styleUrls: ['./verify-micro-deposits.component.scss'],
})
export class VerifyMicroDepositsComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  @Input() dwollaID: string = '';
  amounts: any[];
  amount1: double;
  amount2: double;

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly paymentService: PaymentLegacyApiService,
    private readonly fb: FormBuilder
  ) {
    this.amounts = [
      {ID: "0.01", Title: "$0.01"},
      {ID: "0.02", Title: "$0.02"},
      {ID: "0.03", Title: "$0.03"},
      {ID: "0.04", Title: "$0.04"},
      {ID: "0.05", Title: "$0.05"},
      {ID: "0.06", Title: "$0.06"},
      {ID: "0.07", Title: "$0.07"},
      {ID: "0.08", Title: "$0.08"},
      {ID: "0.09", Title: "$0.09"},
      {ID: "0.10", Title: "$0.10"}
    ];

    this.amount1 = 0;
    this.amount2 = 0;
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async submit() {
    let body: FundingSourceMicroDepositVerification = {
      Amount1: this.amount1,
      Amount2: this.amount2,
    };
    const response = await this.paymentService.verifyFundingSourceMicroDeposits(this.dwollaID, body);
    if (response.isAnyError) {
      return;
    }
    this.modalCtrl.dismiss(true);
  }
}
