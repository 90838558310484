// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid, int } from "../models/ApiTypes";
import { DtoCoachShortForm } from "../models/DtoCoachShortForm";
import { DtoNewCoachWithTeam } from "../models/DtoNewCoachWithTeam";
import { DtoCoachCreate } from "../models/DtoCoachCreate";
import { DtoCoachUpdate } from "../models/DtoCoachUpdate";

@Injectable({providedIn: 'root'})
export class CoachesApiService {
    private readonly controller = "Coaches";

    constructor(private v2Api: V2ApiService) { }

    /** Gets all coaches for current user in given organization.
     * @param organizationID 
     */
    getCoachForCurrentUser(organizationID: Guid) {
        return this.v2Api.get<DtoCoachShortForm[]>(this.controller, "GetCoachForCurrentUser", { organizationID });
    }

    /** Gets all coaches by organization.
     * 
     * Permissions: Trainer or athletics.
     * @param organizationID 
     */
    getByOrganizationID(organizationID: Guid) {
        return this.v2Api.get<DtoNewCoachWithTeam[]>(this.controller, "GetByOrganizationID", { organizationID });
    }

    /** Gets a coach by ID.
     * 
     * Permissions: Organization manager, Coach with coach management permissions, or the current user is the coach.
     * @param id 
     */
    getByID(id: int) {
        return this.v2Api.get<DtoNewCoachWithTeam>(this.controller, "GetByID", { id });
    }

    /** Creates a coach. If UserID is null and User is provided in the body then a new user is created for the coach. Otherwise, a UserID is required.
     * 
     * Returns the Coach ID.
     * 
     * Permissions: Organization manager or Coach with coach management permissions.
     * @param body 
     */
    create(body: DtoCoachCreate) {
        return this.v2Api.post<int>(this.controller, "Create", {  }, body);
    }

    /** Updates a coach.
     * 
     * Permissions: Organization manager or Coach with coach management permissions.
     * @param body 
     */
    update(body: DtoCoachUpdate) {
        return this.v2Api.post<void>(this.controller, "Update", {  }, body);
    }

    /** Deletes a coach
     * 
     * Permissions: Organization manager or Coach with coach management permissions.
     * @param id 
     */
    delete(id: int) {
        return this.v2Api.post<void>(this.controller, "Delete", { id });
    }
}