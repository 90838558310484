import { Component, ViewChild } from '@angular/core';
import { IonIcon, IonModal, ModalController, ViewDidEnter } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { ErrorService } from '../../../../core/services/error/error.service';
import { UserLegacyApiService } from '../../../../core/services/api/v1/UserLegacyApi.service';
import { PotentialOrganization } from '../../../../core/services/api/models/legacy/PotentialOrganization';
import { PotentialOrganizationLegacyApiService } from '../../../../core/services/api/v1/PotentialOrganizationLegacyApi.service';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { OrganizationLegacyApiService } from '../../../../core/services/api/v1/OrganizationLegacyApi.service';
import IContextOrganizationModel from '../../../../core/models/context/context-organization.models';
import { dtoOrganizationSubscriptionSync } from '../../../../core/services/api/models/legacy/dtoOrganizationSubscriptionSync';

@Component({
  selector: 'app-manage-schools',
  templateUrl: './manage-schools.component.html',
  styleUrls: ['./manage-schools.component.scss'],
})
export class ManageSchoolsComponent implements ViewDidEnter {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  staffOrgs: IContextOrganizationModel[] = [];
  newSchools: PotentialOrganization[] = [];
  searchedSchools: PotentialOrganization[] = [];
  zip: string = '';
  name: string = '';
  state: string = '';
  states: any = [];
  currentView: string = 'subscriptions';
  firstLoading = true;
  firstSearchDone = false;
  searching = false;
  schoolNotificationMapping: {
    organization: PotentialOrganization;
    notification: boolean;
    dailySummary: boolean;
  }[] = [];

  constructor(
    private readonly errorService: ErrorService,
    private readonly userService: UserLegacyApiService,
    public modalCtrl: ModalController,
    private readonly potentialOrganizationLegacyApiService: PotentialOrganizationLegacyApiService,
    private readonly utilsService: UtilsService,
    private readonly authService: AuthService,
    private readonly organizationService: OrganizationLegacyApiService
  ) {}

  async ionViewDidEnter() {
    this.searching = false;
    this.firstLoading = true;
    await this.refreshData();
    this.firstLoading = false;
  }

  private async refreshData() {
    this.states = this.utilsService.getStates();
    this.zip = '';
    this.state = '';
    this.name = '';
    this.searchedSchools = [];
    this.newSchools = [];
    this.schoolNotificationMapping = [];
    this.currentView = 'subscriptions';
    this.staffOrgs = [];
    let authResponse = await this.authService.getContextOrganizations();
    for (let authOrganization of authResponse) {
      if (authOrganization.IsStaffOrganization) {
        this.staffOrgs.push(authOrganization);
      }
    }

    let orgResponse = await this.userService.getOrganizationSubscriptions(undefined);
    if (orgResponse.isSuccess && orgResponse.data) {
      let organizations = orgResponse.data;
      for (let organization of organizations) {
        const response = await this.organizationService.find(organization.ID!);
        if (response.isSuccess && response.data) {
          let foundOrganization = response.data;
          let convertedOrg: PotentialOrganization = {
            Address1: foundOrganization.Address1,
            Address2: foundOrganization.Address2,
            City: foundOrganization?.City,
            Contacts: undefined,
            ID: undefined,
            IsActive: true,
            IsCustomer: true,
            OrganizationID: foundOrganization?.ID,
            PhoneAreaCode: undefined,
            PhoneExtension: undefined,
            PhoneNumber: undefined,
            SchoolName: foundOrganization?.Title,
            State: foundOrganization?.State,
            StateAthleticAssociationID: undefined,
            SubscriptionExpirationDate: undefined,
            TimeZone: foundOrganization?.TimeZone,
            TimeZoneAbbreviation: undefined,
            TimeZoneDescription: undefined,
            Zip: foundOrganization?.Zip,
          };
          this.newSchools.push(convertedOrg);
          let notification: boolean;
          let dailySummary: boolean;
          if (organization.AllowNotifications === undefined) {
            notification = false;
          } else {
            notification = organization.AllowNotifications;
          }
          if (organization.AllowDailyEmails === undefined) {
            dailySummary = false;
          } else {
            dailySummary = organization.AllowDailyEmails;
          }

          this.schoolNotificationMapping.push({
            organization: convertedOrg,
            notification: notification,
            dailySummary: dailySummary,
          });
        }
      }
    }
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async save() {
    let orgSync: dtoOrganizationSubscriptionSync[] = [];
    for (let schoolMap of this.schoolNotificationMapping) {
      orgSync.push({
        ID: schoolMap.organization.OrganizationID,
        AllowNotifications: schoolMap.notification,
        AllowDailyEmails: schoolMap.dailySummary,
      });
    }
    let response = await this.organizationService.syncSubscriptions(orgSync);
    if (response.isAnyError) {
      this.errorService.showError(
        'There was an issue trying to save your subscriptions. Please try again later.',
        6000,
        "We're sorry!"
      );
      return;
    } else {
      return this.modalCtrl.dismiss(true, 'save');
    }
  }

  async addSchoolId(school: PotentialOrganization, element: IonIcon) {
    if (!school.OrganizationID) {
      this.errorService.showError(
        "The school you have selected is not an Eventlink customer. Please feel free to contact the school's administration and recommend Eventlink. ",
        6000,
        "We're sorry!"
      );
      return;
    }
    for (let staffOrg of this.staffOrgs) {
      if (staffOrg.OrganizationId?.trim() === school.OrganizationID.trim()) {
        this.errorService.showError(
          'You must remain subscribed to schools where you are a staff member.',
          6000,
          "We're sorry!"
        );
        return;
      }
    }

    let found = false;
    if (element.name === 'ellipse-outline') {
      for (let old of this.newSchools) {
        if (old.OrganizationID === school.OrganizationID) {
          found = true;
        }
      }
      if (!found) {
        this.newSchools.push(school);
        this.schoolNotificationMapping.push({
          organization: school,
          notification: true,
          dailySummary: true,
        });
        if (this.firstSearchDone) {
          await this.queryOrganizations();
        }
      }
      element.name = 'checkmark-circle-outline';
    } else {
      if (this.newSchools.length == 1) {
        this.errorService.showError('You must be subscribed to at least one school.', 6000, "We're sorry!");
        return;
      }
      for (let i = 0; i < this.newSchools.length; i++) {
        if (this.newSchools[i].OrganizationID === school.OrganizationID) {
          this.newSchools.splice(i, 1);
        }
      }
      for (let i = 0; i < this.schoolNotificationMapping.length; i++) {
        if (this.schoolNotificationMapping[i].organization.OrganizationID === school.OrganizationID) {
          this.schoolNotificationMapping.splice(i, 1);
        }
      }
      element.name = 'ellipse-outline';
      if (this.firstSearchDone) {
        await this.queryOrganizations();
      }
    }
  }

  async queryOrganizations() {
    this.firstSearchDone = false;
    this.searching = true;
    const response = await this.potentialOrganizationLegacyApiService.getAllByZipOrName(
      this.name,
      undefined,
      undefined
    );
    if (response.isAnyError) {
      return;
    }
    this.searchedSchools = response.data!;

    await this.removeAlreadyAddedSchoolsFromSearchResults();
    let originalLength = this.searchedSchools.length;
    let accessor = 0;

    if (this.state !== '') {
      for (let i = 0; i < originalLength; i++) {
        if (this.searchedSchools[accessor].State !== this.state) {
          this.searchedSchools.splice(accessor, 1);
          accessor = accessor - 1;
        }
        accessor = accessor + 1;
      }
    }
    accessor = 0;
    originalLength = this.searchedSchools.length;
    if (this.zip && this.zip !== '') {
      for (let i = 0; i < originalLength; i++) {
        if (this.searchedSchools[accessor].Zip != this.zip.toString()) {
          this.searchedSchools.splice(accessor, 1);
          accessor = accessor - 1;
        }
        accessor = accessor + 1;
      }
    }
    if (this.newSchools && this.newSchools.length > 0) {
      for (let i = 0; i < this.newSchools.length; i++) {
        originalLength = this.searchedSchools.length;
        accessor = 0;
        for (let j = 0; j < originalLength; j++) {
          if (this.newSchools[i].ID == this.searchedSchools[accessor].ID) {
            this.searchedSchools.splice(accessor, 1);
            accessor = accessor - 1;
          }
          accessor = accessor + 1;
        }
      }
    }
    this.searching = false;
  }

  async searchSchools() {
    if (this.name && this.name !== '') await this.queryOrganizations();
  }

  async removeAlreadyAddedSchoolsFromSearchResults() {
    for (let i = 0; i < this.searchedSchools.length; i++) {
      for (let j = 0; j < this.newSchools.length; j++) {
        if (this.searchedSchools[i].OrganizationID?.trim() === this.newSchools[j].OrganizationID?.trim()) {
          this.searchedSchools.splice(i, 1);
          i--;
          j = 0;
          break;
        }
      }
    }
  }
}
