// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid, int } from "../models/ApiTypes";
import { DtoArticleSummary } from "../models/DtoArticleSummary";
import { DtoArticleDetails } from "../models/DtoArticleDetails";
import { DtoArticleCreateUpdate } from "../models/DtoArticleCreateUpdate";
import { DtoArticleAttachmentPublic } from "../models/DtoArticleAttachmentPublic";

@Injectable({providedIn: 'root'})
export class ArticlesApiService {
    private readonly controller = "Articles";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param organizationID 
     */
    getByOrganizationID(organizationID: Guid) {
        return this.v2Api.get<DtoArticleSummary[]>(this.controller, "GetByOrganizationID", { organizationID });
    }

    /** 
     * @param id 
     * @param organizationID 
     */
    getByID(id: int, organizationID: Guid) {
        return this.v2Api.get<DtoArticleDetails>(this.controller, "GetByID", { id, organizationID });
    }

    /** 
     * @param body 
     */
    save(body: DtoArticleCreateUpdate) {
        return this.v2Api.post<int>(this.controller, "Save", {  }, body);
    }

    /** 
     * @param id 
     * @param organizationID 
     */
    delete(id: int, organizationID: Guid) {
        return this.v2Api.post<void>(this.controller, "Delete", { id, organizationID });
    }

    /** 
     * @param organizationID 
     * @param articleID  | Due to a Swagger problem, this field is marked as optional but MAY actually be required.
     * @param isFeatured  | Due to a Swagger problem, this field is marked as optional but MAY actually be required.
     * @param file A file upload | Due to a Swagger problem, this field is marked as optional but MAY actually be required.
     */
    addAttachment(organizationID: Guid, articleID: int | undefined, isFeatured: boolean | undefined, file: Blob | undefined) {
        return this.v2Api.post<void>(this.controller, "AddAttachment", { organizationID }, { ArticleID: articleID, IsFeatured: isFeatured, File: file }, true);
    }

    /** 
     * @param organizationID 
     * @param articleID 
     * @param articleAttachmentID 
     */
    removeAttachment(organizationID: Guid, articleID: int, articleAttachmentID: int) {
        return this.v2Api.post<void>(this.controller, "RemoveAttachment", { organizationID, articleID, articleAttachmentID });
    }

    /** Posts article to socials.
     * 
     * If socialAccounts is included the article will only be posted to those.
     * 
     * Permissions: Sites permissions or the user needs to be a coach on a calendar with sites access.
     * @param articleID 
     * @param organizationID 
     * @param socialAccounts 
     */
    postToSocials(articleID: int, organizationID: Guid, socialAccounts: int[] | undefined) {
        return this.v2Api.post<void>(this.controller, "PostToSocials", { articleID, organizationID, socialAccounts });
    }

    /** Gets recent article attachment.
     * 
     * Needs either site or socials permissions.
     * @param organizationID 
     * @param calendarID 
     */
    getRecentArticleAttachments(organizationID: Guid, calendarID: Guid | undefined) {
        return this.v2Api.get<DtoArticleAttachmentPublic[]>(this.controller, "GetRecentArticleAttachments", { organizationID, calendarID });
    }

    /** 
     * @param organizationID 
     * @param articleID 
     * @param articleAttachmentID 
     */
    setFeaturedAttachment(organizationID: Guid, articleID: int, articleAttachmentID: int) {
        return this.v2Api.post<void>(this.controller, "SetFeaturedAttachment", { organizationID, articleID, articleAttachmentID });
    }

    /** Get articles for the current user.
     * 
     * Matches the user to the articles of all teams they are a coach of.
     * 
     * Permissions: Coach with sites access.
     */
    getForCurrentUser() {
        return this.v2Api.get<DtoArticleSummary[]>(this.controller, "GetForCurrentUser", {  });
    }

    /** Gets recent article attachment for the current user.
     * 
     * Matches the user to the article attachments of all teams they are a coach of.
     * 
     * Permissions: Coach with sites access.
     */
    getRecentArticleAttachmentsForCurrentUser() {
        return this.v2Api.get<DtoArticleAttachmentPublic[]>(this.controller, "GetRecentArticleAttachmentsForCurrentUser", {  });
    }
}