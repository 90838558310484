import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentLegacyApiService } from 'src/app/core/services/api/v1/PaymentLegacyApi.service';
import { ITransferDetail } from 'src/app/core/models/payments/transfer-detail';

@Component({
  selector: 'app-search-payments',
  templateUrl: './search-payments.component.html',
  styleUrls: ['./search-payments.component.scss'],
})
export class SearchPaymentsComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  formGroup: FormGroup;
  @Input() payor: string;
  @Input() upperAmount?: number;
  @Input() lowerAmount?: number;
  @Input() dateRange: string;
  @Input() customEarlierDate?: Date;
  @Input() customLaterDate?: Date;
  dwollaCustomerId?: string;
  payments: ITransferDetail[] = [];

  constructor(
    private readonly authService: AuthService,
    public modalCtrl: ModalController,
    private readonly paymentService: PaymentLegacyApiService,
    private readonly fb: FormBuilder
  ) {
    this.payor = '';
    this.dateRange = '30';

    this.formGroup = this.fb.group({
      payor: [''],
      upperAmount: [undefined],
      lowerAmount: [undefined],
      dateRange: ['30'],
      customEarlierDate: [undefined],
      customLaterDate: [undefined],
    });
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async search() {
    let currentDate = new Date();
    let earlierDate = new Date();
    let newDateRange = this.formGroup.get('dateRange')?.value;

    if (this.formGroup.get('dateRange')?.value === 'Custom') {
      if (this.formGroup.get('customEarlierDate')?.value) {
        let newDateString: string = this.formGroup.get('customEarlierDate')?.value;
        let splittingTime = newDateString.split('T');
        let dateBreakdown = splittingTime[0].split('-');
        earlierDate = new Date(Number(dateBreakdown[0]), Number(dateBreakdown[1]) - 1, Number(dateBreakdown[2]));
      }
      if (this.formGroup.get('customLaterDate')?.value) {
        let newDateString: string = this.formGroup.get('customLaterDate')?.value;
        let splittingTime = newDateString.split('T');
        let dateBreakdown = splittingTime[0].split('-');
        currentDate = new Date(Number(dateBreakdown[0]), Number(dateBreakdown[1]) - 1, Number(dateBreakdown[2]));
      }
    } else {
      earlierDate.setDate(currentDate.getDate() - newDateRange);
    }

    let priorDate: string = '';
    if (earlierDate.getDate() < 10) {
      priorDate = '0'.concat(earlierDate.getDate().toString());
    } else {
      priorDate = earlierDate.getDate().toString();
    }

    let priorUTC: string = earlierDate.getUTCFullYear().toString();
    let priorMonth: string = '';
    if (earlierDate.getMonth() + 1 < 10) {
      priorMonth = '0'.concat((earlierDate.getMonth() + 1).toString());
    } else {
      priorMonth = (earlierDate.getMonth() + 1).toString();
    }
    priorUTC = priorUTC.concat('-', priorMonth, '-', priorDate);

    let currentUTC: string = currentDate.getUTCFullYear().toString();
    let currentMonth: string = '';

    let currentDateNumber: string = '';
    if (currentDate.getDate() < 10) {
      currentDateNumber = '0'.concat(currentDate.getDate().toString());
    } else {
      currentDateNumber = currentDate.getDate().toString();
    }

    if (currentDate.getMonth() + 1 < 10) {
      currentMonth = '0'.concat((currentDate.getMonth() + 1).toString());
    } else {
      currentMonth = (currentDate.getMonth() + 1).toString();
    }
    currentUTC = currentUTC.concat('-', currentMonth, '-', currentDateNumber);

    const returnResponse = await this.paymentService.getTransfersForCustomer(
      this.dwollaCustomerId!,
      '', // Fix this by sending undefined.
      undefined,
      undefined,
      priorUTC,
      currentUTC,
      undefined,
      undefined,
      undefined
    );
    if (returnResponse.isAnyError) {
      return;
    }
    for (let payment of returnResponse.data ?? []) {
      this.payments.push({
        ID: payment.ID,
        Status: payment.Status,
        Created: payment.Created,
        Metadata: payment.Metadata ?? [],
        Amount: payment.Amount,
        AmountAsString: payment.AmountAsString,
        CreatedFromTransfer: payment.CreatedFromTransfer,
        Destination: payment.Destination,
        FundingTransfer: payment.FundingTransfer,
        Source: payment.Source,
        SourceFundingSource: payment.SourceFundingSource,
        CanBeCanceled: payment.CanBeCanceled,
      });
    }

    //Clean by Payor
    if (this.payments) {
      let index = 0;
      let originalSize = this.payments.length;
      for (let i = 0; i < originalSize; i++) {
        if (this.formGroup.get('payor')?.value && this.formGroup.get('payor')?.value !== '') {
          if (
            !this.payments[index].Metadata.payorUser
              .toLowerCase()
              .includes(this.formGroup.get('payor')?.value.toLowerCase()) &&
            !this.payments[index].Metadata.payorSchool
              .toLowerCase()
              .includes(this.formGroup.get('payor')?.value.toLowerCase())
          ) {
            this.payments.splice(index, 1);
            index = index - 1;
          }
          index = index + 1;
        }
      }
      //Clean By Lower Amount
      let lowerAmount = this.formGroup.get('lowerAmount');
      if (lowerAmount && lowerAmount.value && lowerAmount.value !== '') {
        index = 0;
        originalSize = this.payments.length;
        for (let i = 0; i < originalSize; i++) {
          if (Number(this.payments[i].Amount) < Number(lowerAmount.value)) {
            this.payments.splice(index, 1);
            index = index - 1;
          }
          index = index + 1;
        }
      }

      let upperAmount = this.formGroup.get('upperAmount');
      //Clean by Upper Amount
      if (upperAmount && upperAmount.value && upperAmount.value !== '') {
        index = 0;
        originalSize = this.payments.length;
        for (let i = 0; i < originalSize; i++) {
          if (Number(this.payments[i].Amount) > Number(upperAmount.value)) {
            this.payments.splice(index, 1);
            index = index - 1;
          }
          index = index + 1;
        }
      }
    }
    this.modalCtrl.dismiss(this.payments);
  }
}
