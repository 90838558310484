import { Injectable } from '@angular/core';
import {
  AppUpdate,
  AppUpdateAvailability,
  AppUpdateResultCode,
  FlexibleUpdateInstallStatus,
  FlexibleUpdateState,
} from '@capawesome/capacitor-app-update';
import { Capacitor } from '@capacitor/core';
import { DialogService } from '../dialog/dialog.service';
import { IAppUpdate } from '../../models/app-update/app-update.model';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  constructor(private readonly dialogService: DialogService) {}

  getCurrentAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();

    if (Capacitor.getPlatform() === 'android') {
      return result.currentVersionCode;
    }

    return result.currentVersionName;
  };

  getAvailableAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();

    if (Capacitor.getPlatform() === 'android') {
      return result.availableVersionCode;
    }

    return result.availableVersionName;
  };

  openAppStore = async () => {
    await AppUpdate.openAppStore();
  };

  performImmediateUpdate = async () => {
    if (!this.isUpdateAvailable) {
      return;
    }

    await AppUpdate.performImmediateUpdate();
  };

  startFlexibleUpdate = async () => {
    if (!this.isUpdateAvailable) {
      return;
    }

    await AppUpdate.startFlexibleUpdate();
  };

  completeFlexibleUpdate = async () => {
    await AppUpdate.completeFlexibleUpdate();
  };

  runUpdateProtocol = async (updateVersionCode: string, handleOutcome: Function) => {
    const appInfo = await AppUpdate.getAppUpdateInfo();
    const update: IAppUpdate = {
      AvailableVersionCode: undefined,
      UpdateSuccessful: undefined,
    };

    await AppUpdate.addListener('onFlexibleUpdateStateChange', async (state: FlexibleUpdateState) => {
      if (state.installStatus === FlexibleUpdateInstallStatus.DOWNLOADED) {
        await this.dialogService.showAlert({
          header: 'Update Complete',
          backdropDismiss: false,
          message:
            'Eventlink has been updated to the latest version. Please restart the app to apply the changes.',
          buttons: [
            {
              text: 'Restart',
              handler: async () => {
                await AppUpdate.completeFlexibleUpdate();
              },
            },
          ],
        });
      }
    });

    if (
      appInfo.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE ||
      updateVersionCode === appInfo.availableVersionCode
    ) {
      return;
    }

    if (Capacitor.getPlatform() === 'android') {
      const flexableUpdateresult = await AppUpdate.startFlexibleUpdate();

      switch (flexableUpdateresult.code) {
        case AppUpdateResultCode.INFO_MISSING:
          return;

        case AppUpdateResultCode.NOT_ALLOWED:
          return;

        case AppUpdateResultCode.FAILED:
          return;

        case AppUpdateResultCode.NOT_AVAILABLE:
          return;

        case AppUpdateResultCode.CANCELED:
          update.AvailableVersionCode = appInfo.availableVersionCode;
          break;

        case AppUpdateResultCode.OK:
          update.UpdateSuccessful = true;
          break;
      }

      handleOutcome(update);
      return;
    }

    await this.dialogService.showAlert({
      header: 'Update Available',
      message: 'A new version of Eventlink is available! Would you like to go to the app store to update now?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Update',
          handler: async () => {
            update.UpdateSuccessful = true;
            handleOutcome(update);

            await AppUpdate.openAppStore();
          },
        },
        {
          text: 'Later',
          handler: async () => {
            update.AvailableVersionCode = appInfo.availableVersionCode;
            handleOutcome(update);

            await this.dialogService.dismissAlert();
          },
        },
      ],
    });
  };

  isUpdateAvailable = async () => {
    const result = await AppUpdate.getAppUpdateInfo();

    return result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
  };
}
