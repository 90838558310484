// THIS FILE IS AUTOGENERATED - EDITS WILL BE OVERWRITTEN
import { Injectable } from "@angular/core";
import { V2ApiService } from "../bases/v2-api.service";
import { Guid, int } from "../models/ApiTypes";
import { DtoOAuthAccessResponse } from "../models/DtoOAuthAccessResponse";
import { DtoOAuthLogin } from "../models/DtoOAuthLogin";
import { DtoOAuthAccessRefreshResponse } from "../models/DtoOAuthAccessRefreshResponse";
import { DtoOAuthMfaVerification } from "../models/DtoOAuthMfaVerification";
import { DtoEnableMultiFactorRequest } from "../models/DtoEnableMultiFactorRequest";
import { DtoEnableMultiFactorResponse } from "../models/DtoEnableMultiFactorResponse";

@Injectable({providedIn: 'root'})
export class OAuthApiService {
    private readonly controller = "OAuth";

    constructor(private v2Api: V2ApiService) { }

    /** 
     * @param userID 
     */
    masquerade(userID: Guid) {
        return this.v2Api.post<DtoOAuthAccessResponse>(this.controller, "Masquerade", { userID });
    }

    /** Login endpoint. If the user has MFA enabled this endpoint will instead return a list of MFA options.
     * @param body 
     */
    login(body: DtoOAuthLogin) {
        return this.v2Api.post<DtoOAuthAccessRefreshResponse>(this.controller, "Login", {  }, body);
    }

    /** Starts MFA process for a user. Only needs called if Login returns a list of MFA options.
     * @param sessionID 
     * @param sessionToken 
     * @param methodID ID from the MFA options
     */
    startMultiFactor(sessionID: int, sessionToken: Guid, methodID: Guid) {
        return this.v2Api.post<void>(this.controller, "StartMultiFactor", { sessionID, sessionToken, methodID });
    }

    /** 
     * @param body 
     */
    verifyMultiFactor(body: DtoOAuthMfaVerification) {
        return this.v2Api.post<DtoOAuthAccessRefreshResponse>(this.controller, "VerifyMultiFactor", {  }, body);
    }

    /** 
     */
    validate() {
        return this.v2Api.get<DtoOAuthAccessResponse>(this.controller, "Validate", {  });
    }

    /** 
     * @param installID 
     */
    logout(installID: Guid | undefined) {
        return this.v2Api.post<void>(this.controller, "Logout", { installID });
    }

    /** 
     */
    logoutAll() {
        return this.v2Api.post<void>(this.controller, "LogoutAll", {  });
    }

    /** Starts the process of enabling multi-factor authentication for a user.
     * @param body 
     */
    startEnableMultiFactorProcess(body: DtoEnableMultiFactorRequest) {
        return this.v2Api.post<DtoEnableMultiFactorResponse>(this.controller, "StartEnableMultiFactorProcess", {  }, body);
    }
}