<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()"> Cancel</ion-button>
    </ion-buttons>
    <ion-title>{{ Edit ? 'Edit' : 'Create' }} Article</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="article-form" class="ion-padding-horizontal" [ngClass]="{'margin-bottom-18': article.VisibleAt && isArticleReadyToPost(article.VisibleAt), 'margin-bottom-12':!article.VisibleAt || !isArticleReadyToPost(article.VisibleAt)}">
    <span class="input-row">
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter title here..."
          type="text"
          [(ngModel)]="article.Title">
          <div slot="label">Title <ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-select label-placement="stacked" [(ngModel)]="article.ArticleType">
          <div slot="label">Type <ion-text color="danger">*</ion-text></div>
          <ion-select-option value="News">News</ion-select-option>
          <ion-select-option value="Announcement">Announcement</ion-select-option>
          <ion-select-option value="PhotoGallery">Photo Gallery</ion-select-option>
        </ion-select>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-select label-placement="stacked" [(ngModel)]="article.CalendarIDs" [multiple]="true">
          <div slot="label">Calendars <ion-text color="danger">*</ion-text></div>
          <ion-select-option *ngFor="let calendar of Calendars" [value]="calendar.ID">{{
            calendar.Title
          }}</ion-select-option>
        </ion-select>
      </ion-item>
    </span>

    <ion-item-group *ngIf="ReadOnlyCalendars?.length" style="padding-left: 1em; padding-right: 1em">
      <ion-label slot="start" style="font-size: small; padding-right: 0.5em; padding-bottom: 0.5em"
        >Other Calendars</ion-label
      >
      <ion-badge class="calendar-badge" *ngFor="let calendar of ReadOnlyCalendars">
        {{ calendar.Title }}
      </ion-badge>
      <ion-item>
        <ion-label style="font-style: italic">
          These calendars cannot be removed from the article because of your current permissions.
        </ion-label>
      </ion-item>
    </ion-item-group>

    <span class="input-row">
      <ion-item lines="none">
        <div style="width: 100%" class="custom-line">
          <div style="font-size: small">Publish Date</div>
          <ion-datetime-button datetime="publishDateTime" style="padding-bottom: 0.5em; justify-content: center">
            <ion-text
              slot="date-target"
              *ngIf="article.VisibleAt === undefined"
              style="opacity: 0.38; justify-content: left"
              >mm/dd/yyyy</ion-text
            >
            <ion-text
              slot="time-target"
              *ngIf="article.VisibleAt === undefined"
              style="opacity: 0.38; justify-content: left"
              >hh:mm am/pm</ion-text
            >
          </ion-datetime-button>
          <ion-modal [keepContentsMounted]="true">
            <ng-template>
              <ion-datetime
                id="publishDateTime"
                [(ngModel)]="article.VisibleAt"
                showClearButton="true"
                showDefaultButtons="true">
              </ion-datetime>
            </ng-template>
          </ion-modal>
        </div>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item>
        <ion-checkbox [(ngModel)]="isPublishedNow" (ionChange)="publishNow()" justify="start" labelPlacement="end">
          Publish Immediately
        </ion-checkbox>
      </ion-item>
    </span>

    <span class="input-row">
      <ion-item lines="none" *ngIf="article.ArticleType === 'Announcement'">
        <div style="width: 100%" class="custom-line">
          <div style="font-size: small">Visible Until</div>
          <ion-datetime-button
            datetime="visibleUntilDateTime"
            style="padding-bottom: 0.5em; justify-content: center">
            <ion-text
              slot="date-target"
              *ngIf="article.VisibleUntil === undefined"
              style="opacity: 0.38; justify-content: left"
              >mm/dd/yyyy</ion-text
            >
            <ion-text
              slot="time-target"
              *ngIf="article.VisibleUntil === undefined"
              style="opacity: 0.38; justify-content: left"
              >hh:mm am/pm</ion-text
            >
          </ion-datetime-button>
          <ion-modal [keepContentsMounted]="true">
            <ng-template>
              <ion-datetime
                id="visibleUntilDateTime"
                [(ngModel)]="article.VisibleUntil"
                showClearButton="true"
                showDefaultButtons="true"></ion-datetime>
            </ng-template>
          </ion-modal>
        </div>
      </ion-item>
    </span>

    <span class="input-row padding-bottom" id="last-item">
      <ion-item>
        <ion-textarea
          id="body-text"
          labelPlacement="stacked"
          placeholder="Enter body text here..."
          type="text"
          [autoGrow]="true"
          [(ngModel)]="article.Body">
          <div slot="label">Body <ion-text color="danger">*</ion-text></div>
        </ion-textarea>
      </ion-item>
    </span>
  </div>

  <div class="content-sticky" id="sticky-footer">
    <div class="save-container">
      <div class="action-button-container" *ngIf="article.VisibleAt && isArticleReadyToPost(article.VisibleAt)">
        <ion-button
          class="post-btn button-height"
          type="submit"
          *ngIf="!article.ID"
          appPreventMultiClickEvents
          (preventMultiClicks)="saveAndPostToSocials(article)"
          expand="block">
          Save & Post To Socials
        </ion-button>
      </div>
      <div class="action-button-container">
        <ion-button
          class="add-btn button-height"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="saveAndAddAttachments(article)"
          expand="block">
          Save & Add Attachments
        </ion-button>
      </div>
      <div class="save-button-container">
        <ion-button
          class="save-btn button-height"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="save(article, '')"
          expand="block">
          Save
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
