<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" type="submit" (click)="cancel()" expand="block">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Multi-factor Auth</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-padding-horizontal">
    <span class="input-row">
      <ion-item lines="none">
        <ion-text class="mfa-description">
          Your account has additional security enabled. Please select how you would like to receive your
          verification code.
        </ion-text>
      </ion-item>
    </span>

    <ion-list [inset]="true" *ngFor="let contact of this.contactPoints" class="mfa-list">
      <ion-row class="method-selection">
        <ion-item class="option-name"> {{ contact.Title }}</ion-item>
        <ion-item class="option-type"> {{ contact.ContactType }}</ion-item>
        <ion-button
          class="mfa-button"
          appPreventMultiClickEvents
          (preventMultiClicks)="startMultiFactorAuth(contact.ID)">
          <ion-text *ngIf="contact.ContactType === 'Email'">Send Email</ion-text>
          <ion-text *ngIf="contact.ContactType === 'Phone'">Send Text</ion-text>
        </ion-button>
      </ion-row>
    </ion-list>
    <span class="input-row">
      <ion-item lines="none">
        <ion-input
          labelPlacement="stacked"
          placeholder="Enter code here."
          [(ngModel)]="mfaCode"
          class="remember-device"></ion-input>
      </ion-item>
    </span>

    <ion-row class="remember-device">
      <ion-checkbox [(ngModel)]="this.rememberDevice" size=""></ion-checkbox
      ><ion-text class="checkbox-text">&nbsp; Remember Device?</ion-text>
    </ion-row>
  </div>

  <div class="content-sticky-footer bottom-bkp-08">
    <div class="footer-container">
      <div class="action-button-container">
        <ion-button
          class="primary-button-footer"
          type="submit"
          appPreventMultiClickEvents
          (preventMultiClicks)="verifyMultiFactorAuth()"
          expand="block">
          <ion-text> Submit </ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
