import { Component, Input, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { SuccessService } from '../../../../core/services/success/success.service';
import { MessageLegacyApiService } from '../../../../core/services/api/v1/MessageLegacyApi.service';
import { ErrorService } from '../../../../core/services/error/error.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  @Input() username: string;

  constructor(
    private readonly successService: SuccessService,
    private readonly errorService: ErrorService,
    private readonly messageLegacyApiService: MessageLegacyApiService,
    public modalCtrl: ModalController
  ) {
    this.username = '';
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async submit() {
    if (!this.username) {
      // This probably isn't how this is supposed to be done, but it's an improvement on the nothing that's here currently
      this.errorService.showError('You must enter a username.');
      return;
    }

    const response = await this.messageLegacyApiService.sendPasswordResetEmail(this.username);
    if (response.isSuccess) {
      this.successService.showSuccess('An email has been sent to reset your password.', 5000, 'Email Sent');
      await this.modalCtrl.dismiss();
    } else if (response.isNotFound) {
      this.errorService.showError('Username not found. Please try using Forgot Username.');
    }
  }
}
